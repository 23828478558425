import L from "leaflet";

L.TileLayer.Unwired = L.TileLayer.extend({
  options: {
    subdomains: "abc",
    minZoom: 2,
    maxNativeZoom: 20,
    maxZoom: 22,
    scheme: "streets",
    format: "png",
    key: "", //access token, not the main API token!
    attributions: [], //depends on map layer,
    tileServer: "https://{s}-tiles.locationiq.com",
    path: "/v3/{scheme}/r/{z}/{x}/{y}{tileResolution}.{format}?key={key}",
    proxy: false,
  },

  initialize: function initialize(options) {
    options = L.setOptions(this, options);

    // If it's not Retina, don't add anything here, it'll be 256px by default
    options.tileResolution = "";

    if (options.scheme === "streets") {
      options.attributions = [
        '&copy; <a href="https://locationiq.com/?ref=maps" target="_blank">LocationIQ Maps</a>',
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
      ];
      options.scheme = "streets";
      options.tileServer = options.proxy
        ? "/map-proxy/streets/{s}"
        : "https://{s}-tiles.locationiq.com";
      options.key = "pk.6ed9cebcc44f553e3a45117cb9cb2ce4";
    } else if (options.scheme === "satellite") {
      options.attributions = [
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      ];
      options.scheme = "mapbox.satellite";
      options.tileServer = options.proxy
        ? "/map-proxy/satellite/{s}"
        : "https://api.tiles.mapbox.com";
      options.path =
        "/v4/{scheme}/{z}/{x}/{y}{tileResolution}.{format}?access_token={key}";
      options.key =
        "pk.eyJ1IjoiamFuLXNlbnNvbHVzIiwiYSI6ImNrN2JpMW92ejAwMGszbm92anppbzk0MXgifQ.saRkIIeZfUFV0e3jxdT3gw";
    }

    //If it's Retina, we need to serve 512px tiles
    if (L.Browser.retina) {
      options.tileResolution = "@2x";
    }

    // STIC-5041: needs to depend on options to allow proxying !!!
    var tileUrl = options.tileServer + options.path;

    L.TileLayer.prototype.initialize.call(this, tileUrl, options);
  },

  onAdd: function onAdd(map) {
    L.TileLayer.prototype.onAdd.call(this, map);

    if (!this._attributionBBoxes && this.options.attributions?.length) {
      this._findCopyrightBBox(this.options);
    }
  },

  onRemove: function onRemove(map) {
    L.TileLayer.prototype.onRemove.call(this, map);

    if (this._attributionText && this._map?.attributionControl) {
      this._map.attributionControl.removeAttribution(this._attributionText);
    }

    this._map.off("moveend zoomend resetview", this._findCopyrightBBox, this);
  },

  _findCopyrightBBox: function _findCopyrightBBox(options) {
    if (!this._map?.attributionControl || !options.attributions?.length) return;

    const attributionText = options.attributions.join(", ");
    this._map.attributionControl.addAttribution(
      (this._attributionText = attributionText)
    );
  },
});

L.tileLayer.Unwired = function (opts) {
  return new L.TileLayer.Unwired(opts);
};
export const LayerUnwired = L.tileLayer.Unwired;
