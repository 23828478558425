import { useMemo } from "react";

import { useSelector } from "react-redux";

import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";
import searchUIClient from "@/apis/searchUIClient";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import { useOrg } from "@/contexts/OrgContext";
import FilterUtils from "@/components/AdvancedFilters/FilterUtils";
import AutomationRuleTable from "./AutomationRuleTable";
import SntLabIcon from "@/components/Icons/SntLabIcon";

import { AutomationRuleRoute } from "@/cleanup/utils/routesUtil/routesUtil";

const AutomationRule = ({ reload }) => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    FilterUtils.addOrgInSidebarToFilter(params, orgId);
    params["selectedOrgId"] = orgId;

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (permissionsMap["AUTOMATION_MANAGE"]) {
      list.push([
        {
          id: "heroAction",
          title: language.create_automation_rule_key,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: AutomationRuleRoute.getCreatePage({
            orgId: orgId || loginInfo.organisation.id,
          }),
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SaveViewPage
      pageName={PAGES.AUTOMATION.pageName}
      reload={reload}
      title={language.automation_rule_key}
      extraTitle={
        <SntLabIcon
          className={"ms-2"}
          style={{ marginTop: "-3px" }}
          title={language.lab_feature_key}
        />
      }
      onRequestData={onRequestData}
      tableRender={() => {
        return <AutomationRuleTable />;
      }}
      sections={sections}
      orgId={orgId}
    />
  );
};

export default AutomationRule;
