const _externalBLESensorOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "SIGFOX_DEVICE";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "techProductDescriptorKey",
        "serial",
        "trackerStatus",
        "filter_sensor_last_received_data_point",
        "manufacturerSerial",
      ],
      restrictedFilters: [
        "assetName",
        "assetTagsName",
        "locationByBound",
        "deviceCategory",
        "lastNetworkOverridePrecise",
        "alertRuleName",
        "decoratorColor",
      ],
      categories: "SENSOR",
      deviceCategorySuggestion: "SENSOR",
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "serial",
        "mac",
        "status",
        "deviceTag",
        "techProductDescriptorKey",
        "linked_device",
        "requested_col_creation_date",
        "manufacturerSerial",
        "requested_col_last_received_data_point",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  if (orgId) {
    pageOptions.filterOptions.restrictedFilters.push("organizations");
  }

  return pageOptions;
};

export default _externalBLESensorOptions;
