import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntLink from "@/components/ReactBootstrap/SntLink";

import { AutomationRuleRoute as PageRoute } from "@/cleanup/utils/routesUtil/routesUtil";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import automationRuleClient from "@/apis/automationRuleClient";
import {
  ACTION_MAPPING,
  CONDITION_MAPPING,
} from "./components/automationRuleUtils";
import AlertSwitch from "@/cleanup/pages/AlertRule/AlertSwitch";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const AutomationRuleTable = ({ orgId }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const history = useHistory();

  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        Cell: ({ cell, value }) => {
          if (permissionsMap["AUTOMATION_VIEW"]) {
            return (
              <SntLink
                onClick={(event) => {
                  event.preventDefault();
                  history.push(
                    PageRoute.getUpdatePage({
                      type: "view",
                      id: cell.row.original.id,
                      orgId: orgId,
                    })
                  );
                }}
              >
                {value}
              </SntLink>
            );
          }
          return <>{value}</>;
        },
      },
      {
        key: "condition",
        Cell: ({ cell, value }) => {
          return (
            <>
              {CONDITION_MAPPING[cell.row.original.conditionType]?.getLabel(
                language
              ) || ""}
            </>
          );
        },
        cellFilterValue: (data) => {
          return [data.conditionType];
        },
        cellCopyData: (data) => {
          return [data.conditionType];
        },
      },
      {
        key: "actionType",
        Cell: ({ value }) => {
          return <>{ACTION_MAPPING[value]?.getLabel(language) || ""}</>;
        },
        cellFilterValue: (data) => {
          return [data.actionType];
        },
        cellCopyData: (data) => {
          return [data.actionType];
        },
      },
      {
        key: "active",
        Cell: ({ cell, value }) => {
          let full = cell.row.original;

          if (full.id) {
            return (
              <AlertSwitch
                active={full.active}
                disabled={!permissionsMap["AUTOMATION_MANAGE"]}
                onSwitchChange={onSwitchChange}
                id={full.id}
              />
            );
          }

          return <>{full.active}</>;
        },
        cellFilterValue: (data) => {
          return [data.active];
        },
        cellCopyData: (data) => {
          return [data.active];
        },
      },
    ];
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.action_key,
        disableSortBy: true,
        canResize: false,
        immovable: true,
        Cell: ({ cell }) => {
          return (
            <>
              {permissionsMap["AUTOMATION_MANAGE"] && (
                <>
                  <SntActionButton
                    to={PageRoute.getUpdatePage({
                      type: "edit",
                      id: cell.row.original.id,
                      orgId: orgId,
                    })}
                  >
                    <SntPencilIcon />
                  </SntActionButton>
                  <SntActionButton
                    title={language.delete_key}
                    onClick={(e) => onDeleteClicked(e, cell.row.original)}
                  >
                    <SntDeleteIcon />
                  </SntActionButton>
                </>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      automationRuleClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          let message = language.request_error_key;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  const onSwitchChange = (checked, id) => {
    automationRuleClient
      .active(id, checked)
      .then(() => {
        table.current.refresh();
        console.log("update active successfully!");
      })
      .catch((error) => {
        console.log("failed to update active!");
      });
  };

  return (
    <div>
      <DeleteDialog />

      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </div>
  );
};

export default AutomationRuleTable;
