import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  bucketTypeConstant,
  supportedGroupTypeConstant,
} from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";
import SntLink from "@/components/ReactBootstrap/SntLink";
import { useSelector } from "react-redux";
import { getGroupColumnData } from "@/cleanup/utils/tableUtils";
import moment from "moment";

/**
 * Custom hook for managing data filtering, pagination, and sorting.
 *
 * @param {Array} allColumnDefs - The definition of all available columns.
 * @param {Array} defaultVisibleColumnKeys - The keys of the columns to be shown and ordered.
 * @param {Object} initialState - Optional initial state for the filter.
 * @returns {Object} - An object containing filter state and functions to interact with it.
 */
export const useColumnFilter = (allColumnDefs, initialState, setting) => {
  const [filter, setFilter] = useState(null);
  const language = useSelector((state) => state.language);

  const [initState, setInitState] = useState(null);
  const [allColumns, setAllColumns] = useState(null);

  const [pageCount, setPageCount] = useState(1);
  const [totalItems, setTotalItems] = useState({
    countByFilter: 0,
  });

  let allColumnsByKeyRef = useRef();
  let immovableColumnsRef = useRef();
  let defaultColumnsRef = useRef();

  let allColumnsByKey = allColumnsByKeyRef.current;
  let immovableColumns = immovableColumnsRef.current;
  let defaultColumns = defaultColumnsRef.current;
  const {
    defaultVisibleColumnKeys,
    defaultPageSize = 0,
    defaultSortBy,
    onCountClicked,
  } = initialState;

  useEffect(() => {
    if (!allColumnDefs) return;
    const allColumnTransform = _transformColumns(allColumnDefs);
    allColumnsByKeyRef.current = _.keyBy(
      allColumnTransform,
      (o) => o.columnKey
    );
    immovableColumnsRef.current = allColumnTransform
      .filter((c) => (c.immovable ? c : null))
      .filter((c) => c);
    setAllColumns(allColumnTransform);
  }, [allColumnDefs]);

  useEffect(() => {
    if (!allColumns) return;
    defaultColumnsRef.current = getOrderedColumns(defaultVisibleColumnKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumns]);

  useEffect(() => {
    if (initState) return;

    if (_.isNil(setting)) {
      setInitState({
        columnVisibleInOrder: defaultVisibleColumnKeys,
        pageSize: defaultPageSize,
      });
    } else if (!_.isEmpty(setting)) {
      setInitState({
        columnVisibleInOrder:
          setting.columnVisibleInOrder &&
          setting.columnVisibleInOrder.length !== 0
            ? setting.columnVisibleInOrder
            : defaultVisibleColumnKeys,
        pageSize: setting.pageSize || defaultPageSize,
        pageIndex: setting.pageIndex,
        orderData: setting.orderData,
        groups: setting.groups,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, initState]);

  useEffect(() => {
    if (!allColumns || !initState || filter) return;

    const columns = getOrderedColumns(initState.columnVisibleInOrder);

    metricColumnsRef.current = [];
    let groupCol;
    if (initState.groups && initState.groups.length) {
      const aggregateColumn = initState.groups[0].aggregateColumn; // this is come by UI settings, it is columnKey
      const bucketType = initState.groups[0].bucketType;
      const bucketConfig = initState.groups[0].bucketConfig;

      groupCol = columns.find((column) => column.columnKey === aggregateColumn);

      if (groupCol) {
        groupCol.isGrouped = true;
        groupCol.bucketType = bucketType;
        groupCol.bucketConfig = bucketConfig;

        let uiSettingMetrics = initState.groups.filter(
          (metricObj) => metricObj.aggregationType
        );

        if (uiSettingMetrics.length === 0) {
          // add count metric as default
          metricColumnsRef.current = [
            getAggregateMetricsColumn(
              groupCol.columnKey + ".count",
              groupCol,
              language
            ),
          ];
        } else {
          metricColumnsRef.current = uiSettingMetrics
            .map((metricObj) => {
              let meticColumn = columns.find(
                (column) => column.columnKey === metricObj.aggregateColumn
              );
              if (!meticColumn) return null;
              return getAggregateMetricsColumn(
                meticColumn.columnKey +
                  "." +
                  metricObj.aggregationType.toLowerCase(),
                meticColumn,
                language
              );
            })
            .filter((item) => item);
        }

        columns.push(...metricColumnsRef.current);
      }
    }

    let sortBy = null;
    if (initState.orderData && initState.orderData.length === 2) {
      let orderKey = initState.orderData[0],
        desc =
          initState.orderData[1] === "desc" || initState.orderData[1] === "DESC"
            ? true
            : false;

      if (groupCol) {
        if (groupCol.columnKey === orderKey) {
          sortBy = {
            column: groupCol,
            desc: desc,
            visible: true,
          };
        } else {
          let metricColumn = findMetricColumn(orderKey);
          if (metricColumn) {
            sortBy = {
              column: metricColumn,
              desc: desc,
              visible: true,
            };
          }
        }
      } else {
        const orderColumn = columns.find((column) => column.key === orderKey);
        if (orderColumn) {
          sortBy = {
            column: orderColumn,
            desc: desc,
            visible: true,
          };
        }
      }
    }

    if (!sortBy) {
      // get default sortBy
      if (groupCol) {
        sortBy = {
          column: groupCol,
          desc: false,
          visible: true,
        };
      } else {
        let sortColumn = null,
          desc;

        if (defaultSortBy?.length) {
          sortColumn = defaultColumnsRef.current.find(
            (item) => item.key === defaultSortBy[0]
          );
          desc = defaultSortBy[1];
        }
        if (!sortColumn) {
          sortColumn = defaultColumnsRef.current[0];
          desc = defaultColumnsRef.current[0].desc;
        }

        sortBy = {
          column: sortColumn,
          desc: desc,
          visible: true,
        };
      }
    }

    setFilter({
      pageSize: initState.pageSize || 25,
      pageIndex: initState.pageIndex || 0,
      sortBy: sortBy,
      columns: columns,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumns, initState, filter]);

  const getOrderedColumns = (columnKeys, isOrdered = false) => {
    // sort by columnKeys and add immovables at the back !
    let keys = Array.from(new Set(columnKeys));
    let columns = keys
      .map((key) =>
        allColumnsByKey[key] && !allColumnsByKey[key].immovable
          ? allColumnsByKey[key]
          : null
      )
      .filter((n) => n);

    if (isOrdered) {
      columns.sort((col1, col2) => {
        return col1.sortedIndex - col2.sortedIndex;
      });
    }

    return columns.concat(immovableColumns);
  };

  const getAggregateMetricsColumn = (key, groupCol, language) => {
    let column = {
      ...groupCol,
      groupKey: groupCol.columnKey,
      columnKey: key,
      sortKey: key,
      disabledGroupColumn: true,
      isGrouped: false,
      id: key,
      key: key,
      filterKeys: [],
    };
    if (key.includes(".count")) {
      return {
        ...column,
        Header: "Count: " + column.Header,
        description: language.group_count_description_key,
        aggregationType: "COUNT",
        Cell: ({ cell, value }) => {
          return countColumnRendering(
            cell.row.original,
            column,
            groupCol.bucketType,
            groupCol.bucketConfig
          );
        },
      };
    } else if (key.includes(".min")) {
      return {
        ...column,
        Header: "Min: " + column.Header,
        description: "Min",
        aggregationType: "MIN",
        Cell: ({ cell, value }) => {
          let aliasName = column.aliasName;
          let rs = cell.row.original[aliasName + ".min"];

          let supportedGroupType = column.tableDescriptor.supportedGroupType;
          if (supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET) {
            return moment(rs).format("lll");
          }
          return <>{rs}</>;
        },
      };
    } else if (key.includes(".max")) {
      return {
        ...column,
        Header: "Max: " + column.Header,
        description: "Max",
        aggregationType: "MAX",
        Cell: ({ cell, value }) => {
          let aliasName = column.aliasName;
          let rs = cell.row.original[aliasName + ".max"];

          let supportedGroupType = column.tableDescriptor.supportedGroupType;
          if (supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET) {
            return moment(rs).format("lll");
          }
          return <>{rs}</>;
        },
      };
    } else if (key.includes(".avg")) {
      return {
        ...column,
        Header: "AVG: " + column.Header,
        description: "AVG",
        aggregationType: "AVG",
        Cell: ({ cell, value }) => {
          let aliasName = column.aliasName;
          return <>{cell.row.original[aliasName + ".avg"]}</>;
        },
      };
    } else {
      return column;
    }
  };

  const countColumnRendering = (full, column, bucketType, bucketConfig) => {
    let aliasName = column.aliasName;
    if (
      onCountClicked &&
      column.redirectFilter &&
      full[aliasName] &&
      full[aliasName] !== '{"id": null, "name": null}'
    ) {
      return (
        <SntLink
          onClick={() => {
            onCountClicked(
              column.redirectFilter,
              full[aliasName],
              bucketType,
              bucketConfig
            );
          }}
        >
          {full[aliasName + ".count"] || 0}
        </SntLink>
      );
    }

    return full[aliasName + ".count"] || 0;
  };

  const loadData = () => {
    setPaging(filter.pageIndex, filter.pageSize);
    setPageCount(1);
    _updateFilter({});
  };

  const resetDefault = () => {
    setPaging(0, filter.pageSize);
    setSortByKey(defaultColumns[0], false);
  };

  const refresh = () => {
    _updateFilter({});
  };

  const setVisibleColumnKeys = (visibleColumns, isOrdered = false) => {
    let newColumns = [];
    if (visibleColumns && visibleColumns.length > 0) {
      newColumns = getOrderedColumns(visibleColumns, isOrdered);
    }
    if (!newColumns.length) {
      newColumns = defaultColumns;
    }

    let columns = filter.columns.filter((col) => !col.aggregationType);
    newColumns = newColumns.map((item) => {
      const rs = { ...item };
      delete rs.isGrouped;
      return rs;
    });
    if (!_.isEqual(columns, newColumns)) {
      _updateFilter({ columns: newColumns });
    }
  };

  const setPaging = (pageIndex, pageSize) => {
    if (pageIndex !== filter.pageIndex || pageSize !== filter.pageSize) {
      _updateFilter({ pageSize: pageSize, pageIndex: pageIndex });
    }
  };

  const setSortByKey = (key, desc) => {
    let sortColumn = allColumnsByKey[key];
    if (!sortColumn) {
      sortColumn = findMetricColumn(key);
    }
    if (sortColumn && _sortChanged(sortColumn, desc)) {
      _updateFilter({
        sortBy: {
          column: sortColumn,
          desc: desc,
          visible: _isVisible(sortColumn),
        },
        pageIndex: 0,
      });
    }
  };

  // const setFilterField = (name, value) => {
  //   if (!_.isEqual(value, filter[name])) {
  //     _updateFilter({ [name]: value });
  //   }
  // };

  const _sortChanged = (column, desc) => {
    return (
      !_.isEqual(column, filter.sortBy.column) || desc !== filter.sortBy.desc
    );
  };

  const _isVisible = (column) => {
    return (
      filter.columns &&
      filter.columns.filter((c) => c.columnKey === column.columnKey).length > 0
    );
  };

  const _updateFilter = (props) => {
    setFilter((prevFilter) => {
      let value = {
        ...prevFilter,
        ...props,
      };
      return value;
    });
  };

  const _transformColumns = (columns) => {
    return columns.map((column) => {
      return {
        ...column,
        sortedIndex: column.sortedIndex,
        categoryId: column.categoryId || "",
        category: column.category || "",
        columnKey: column.key,
        supportKibanaKey: column.supportKibanaKey,
        supportedGroupType: column.supportedGroupType,
        supportedAggregateMetrics: column.supportedAggregateMetrics,
        supportedGroupUnits: column.supportedGroupUnits,
        filterKeys: column.filterKeys,
        cellCopyData: column.cellCopyData,
        cellFilterValue: column.cellFilterValue,
        aggregateColumn: column.aggregateColumn,
        aliasName: column.aliasName,
        redirectFilter: column.redirectFilter,
        disabledGroupColumn: column.disabledGroupColumn,
        sortKey: column.sortKey || column.key,
        description: column.description ? column.description : "",
        title: column.title,
        accessor: column.key,
        Header: column.title || "",
        width: column.style && column.style.width ? column.style.width : null,
        minWidth:
          column.style && column.style.minWidth ? column.style.minWidth : 150,
        maxWidth:
          column.style && column.style.maxWidth ? column.style.maxWidth : 600,
      };
    });
  };

  function getPageData() {
    let sort = null;
    if (filter.sortBy) {
      sort = `[{"property": "${filter.sortBy.column.sortKey}", "direction": "${
        filter.sortBy.desc ? "DESC" : "ASC"
      }"}]`;
    }

    let columns = filter.columns.filter((c) => c.key !== "actions");
    let { groupColumn, groups } = getGroupColumnData(columns);

    if (groupColumn) {
      return {
        start: filter.pageSize * filter.pageIndex,
        limit: filter.pageSize,
        sort: sort,
        isGrouped: true,
        groups: groups, // use in tableSetting for save filter
        columns: columns.map((column) => {
          let findGroup = groups.find(
            (item) => item.columnKey === column.columnKey
          );
          if (findGroup) {
            return {
              ...findGroup,
              order:
                filter.sortBy.column.columnKey === column.columnKey
                  ? filter.sortBy.desc
                    ? "DESC"
                    : "ASC"
                  : null,
            };
          }

          return {
            columnKey: column.columnKey,
            order: null,
          };
        }),
        requestedTableColumns: groups.map((column) => {
          return {
            columnKey: column.columnGroupKey,
            bucketType: column.aggregationType ? null : column.bucketType,
            bucketConfig: column.aggregationType ? null : column.bucketConfig,
            aggregationType: column.aggregationType,
            order:
              filter.sortBy.column.columnKey === column.columnKey
                ? filter.sortBy.desc
                  ? "DESC"
                  : "ASC"
                : null,
          };
        }),
      };
    }

    return {
      start: filter.pageSize * filter.pageIndex,
      limit: filter.pageSize,
      sort: sort,
      columns: columns.map((column) => {
        return {
          columnKey: column.columnKey,
          order:
            filter.sortBy.column.columnKey === column.columnKey
              ? filter.sortBy.desc
                ? "DESC"
                : "ASC"
              : null,
        };
      }),
      requestedTableColumns: columns.map((column) => column.key),
    };
  }
  const metricColumnsRef = useRef([]);

  const toggleGroupColumnKey = (columnKey, isForceGroup) => {
    // remove grouped
    let columns = filter.columns
      .filter((column) => !column.aggregationType)
      .map((column) => {
        if (column.key !== columnKey) delete column.isGrouped;
        if (isForceGroup) delete column.isGrouped;
        return column;
      });

    let firstCol = columns.find((column) => column.key === columnKey);
    // The following grouping logic relies on the user clicking a column header to initiate the grouping.
    // The grouped column may become undefined if this grouping action is used at another place
    if (!firstCol) {
      firstCol = allColumnsByKey[columnKey];
      columns.push(firstCol);
    }
    firstCol.isGrouped = !firstCol.isGrouped;

    // needed for mobile card checking false/true/null
    if (!firstCol.isGrouped) {
      delete firstCol.isGrouped;
      delete firstCol.bucketType;
      delete firstCol.bucketConfig;
    }

    let sortBy = filter.sortBy;
    sortBy.column = firstCol;

    metricColumnsRef.current = [];
    if (firstCol.isGrouped) {
      sortBy = { ...sortBy };
      sortBy.desc = false;

      let bucketType =
          firstCol.supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET
            ? bucketTypeConstant.DAY
            : firstCol.supportedGroupType ===
              supportedGroupTypeConstant.NUMERIC_BUCKET
            ? bucketTypeConstant.AUTO_WIDTH
            : firstCol.supportedGroupType ===
              supportedGroupTypeConstant.TAG_BASED
            ? bucketTypeConstant.TAGS_FULL
            : null,
        bucketConfig = null;
      firstCol.bucketType = bucketType;
      firstCol.bucketConfig = bucketConfig;

      metricColumnsRef.current = firstCol.supportedAggregateMetrics.map(
        (metric) =>
          getAggregateMetricsColumn(
            columnKey + "." + metric.toLowerCase(),
            firstCol,
            language
          )
      );
    }

    _updateFilter({
      columns: [...columns, ...metricColumnsRef.current],
      sortBy: sortBy,
      pageIndex: 0,
    });
  };

  const setColumnsSetting = (
    columnKeys,
    sortBy = { key: "", desc: false },
    groupKey,
    bucketType,
    bucketConfig,
    aggregateMetrics
  ) => {
    let columns = [];
    if (columnKeys.length > 0) {
      columns = getOrderedColumns(columnKeys, false);
    }
    if (!columns.length) {
      columns = defaultColumns;
    }

    columns.map((column) => {
      delete column.isGrouped;
      delete column.bucketType;
      delete column.bucketConfig;
      return column;
    });

    let sort = filter.sortBy;

    metricColumnsRef.current = [];
    if (groupKey) {
      let groupCol = columns.find((column) => column.key === groupKey);
      groupCol.isGrouped = true;
      groupCol.bucketType =
        groupCol.supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET
          ? bucketType || bucketTypeConstant.DAY
          : groupCol.supportedGroupType ===
            supportedGroupTypeConstant.NUMERIC_BUCKET
          ? bucketType || bucketTypeConstant.AUTO_WIDTH
          : groupCol.supportedGroupType === supportedGroupTypeConstant.TAG_BASED
          ? bucketType || bucketTypeConstant.TAGS_FULL
          : null;
      groupCol.bucketConfig =
        groupCol.supportedGroupType ===
          supportedGroupTypeConstant.NUMERIC_BUCKET &&
        bucketType === bucketTypeConstant.WIDTH
          ? bucketConfig
          : null;

      let metrics =
        aggregateMetrics?.length > 0 ? aggregateMetrics : [groupKey + ".count"];
      metricColumnsRef.current = metrics.map((metric) => {
        const metricColumnKey = metric.split(".")[0];
        const metricColumn = columns.find(
          (column) => column.key === metricColumnKey
        );
        return getAggregateMetricsColumn(metric, metricColumn, language);
      });

      if (groupCol.key === sortBy.key) {
        sort.column = groupCol;
      } else {
        let metricColumn = findMetricColumn(sortBy.key);
        sort.column = metricColumn || metricColumnsRef.current[0];
      }
      sort.desc = sortBy.desc || false;
    } else {
      let sortColumn = columns.find((column) => column.key === sortBy.key);
      sort.column = sortColumn || columns[0];
      sort.desc = sortBy.desc || false; // sort.desc does not accept undefined or null value
    }

    _updateFilter({
      columns: [...columns, ...metricColumnsRef.current],
      sortBy: { ...sort },
      pageIndex: 0,
    });
  };

  const findMetricColumn = (key) => {
    return metricColumnsRef.current.find((column) => column.key === key);
  };

  const removeColumnKey = (columnKey) => {
    let columns = filter.columns.filter((column) => column.key !== columnKey);
    _updateFilter({ columns: columns });
  };

  if (!filter) return {};
  return {
    filter,
    allColumns,
    getPageData,
    setPaging,
    pageCount,
    setPageCount,
    setSortByKey,
    // setFilterField,
    setVisibleColumnKeys,
    refresh,
    loadData,
    resetDefault,
    totalItems,
    setTotalItems,
    removeColumnKey,
    toggleGroupColumnKey,
    defaultVisibleColumnKeys,
    setColumnsSetting,
  };
};
