import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";

import { useOrg } from "@/contexts/OrgContext";
import { useSelector } from "react-redux";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import searchUIClient from "@/apis/searchUIClient";
import RMADeclarationViewTableSaveView from "./RMADeclarationViewTableSaveView";

const RMADeclarationView = ({ reload }) => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);

  const onRequestData = (params, successCallback, errorCallback) => {
    if (orgId) {
      params["selectedOrgId"] = orgId;
    }

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  return (
    <SaveViewPage
      pageName={PAGES.RMA_DECLARATION_VIEW.pageName}
      reload={reload}
      onRequestData={onRequestData}
      orgId={orgId}
      title={language.device_return}
      tableRender={() => {
        return <RMADeclarationViewTableSaveView orgId={orgId} />;
      }}
      topContentRender={() => (
        <SntInfoPanel>
          <p>
            {language.desc_about_the_return_process}
            <br />
            {language.desc_about_the_return_process_1}
            <br />
            {language.desc_about_the_return_process_2}
            <br />
            {language.desc_about_the_return_process_3}
            <br />
            {language.desc_about_the_return_process_4}
          </p>
        </SntInfoPanel>
      )}
    />
  );
};

export default RMADeclarationView;
