const _legalDocumentOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "LEGAL_DOCUMENT";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["partners", "type", "revision", "status", "user"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "partners",
        "type",
        "revision",
        "status",
        "createdAt",
        "expireAt",
        "user",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _legalDocumentOptions;
