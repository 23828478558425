import { useEffect, useRef, useState } from "react";

import useSaveView, {
  SaveViewProvider,
} from "@containers/SaveViewContainer/useSaveView";
import FiltersSaveView from "@containers/SaveViewContainer/FiltersSaveView";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { useSelector } from "react-redux";
import PagePermissionWarning from "@/containers/AdminLayout/PagePermissionWarning/PagePermissionWarning";
import Toolbar from "@/containers/AdminLayout/Toolbar/Toolbar";
import ListSaveView from "./ListSaveView";
import SntListIcon from "@/components/Icons/SntListIcon";
import SntMapIcon from "@/components/Icons/SntMapIcon";
import ButtonsSaveView from "./ButtonsSaveView";
import PageLayout from "../layouts/PageLayout";

const SaveViewPage = ({
  pageName,
  reload,
  onRequestData, //TO BE DEPRECATED
  onRobustRequestData,
  tableRender,
  mapRender,
  filterRender,
  topContentRender,

  orgId,
  isPartner = null,
  isSystem = null,

  title,
  extraTitle,
  showPermissionWarning = true,
  handleActionData,
  sections,
  enableSwitchMode = false,
  emptyData,
}) => {
  const [key, setKey] = useState(0);
  const isFirstRender = useRef(true);

  const onResetSaveView = () => {
    setKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setKey((prev) => prev + 1);
  }, [reload, orgId]);

  return (
    <SaveViewProvider
      key={key}
      initialState={{
        pageName: pageName,
        orgId: orgId,
        isPartner: isPartner,
        isSystem: isSystem,
      }}
    >
      <SaveViewPageContent
        title={title}
        extraTitle={extraTitle}
        onRequestData={onRequestData}
        onRobustRequestData={onRobustRequestData}
        orgId={orgId}
        tableRender={tableRender}
        mapRender={mapRender}
        filterRender={filterRender}
        topContentRender={topContentRender}
        sections={sections}
        handleActionData={handleActionData}
        showPermissionWarning={showPermissionWarning}
        enableSwitchMode={enableSwitchMode}
        onResetSaveView={onResetSaveView}
        emptyData={emptyData}
      />
    </SaveViewProvider>
  );
};

const SaveViewPageContent = ({
  orgId,
  title,
  extraTitle,
  onRequestData,
  onRobustRequestData,
  tableRender,
  mapRender,
  filterRender,
  topContentRender,
  showPermissionWarning = true,
  sections,
  handleActionData,
  onResetSaveView,
  enableSwitchMode = false,
  emptyData,
}) => {
  const language = useSelector((state) => state.language);
  const [fixedSections, setFixedSections] = useState([]);

  const {
    saveViewState,
    requestedTableColumns,
    requestedMapColumns,
    filterOptions,
    isFilterValid,
    isTableValid,
    isMapValid,
    tableOptions,
    mapOptions,
    setForceTableAction,
    setForceMapAction,
    isLoading,
    isChanged,
    updateSwitchMode,
    getOldStructureOptionSettings,
  } = useSaveView();

  const [isShowEmptyData, setShowEmptyData] = useState(null);

  useEffect(() => {
    if (!onRequestData && !onRobustRequestData) return;

    let timeout = setTimeout(() => {
      console.log("timeout");
      if (
        saveViewState.switchMode === "TABLE" &&
        isFilterValid &&
        isTableValid
      ) {
        let params = {
          switchMode: "TABLE",
          queryObjectType: filterOptions.queryObjectType,
          searchQueryType: saveViewState.filter.searchQueryType,
          startIndex:
            saveViewState.table.pageSize * saveViewState.table.pageIndex,
          maxResults: saveViewState.table.pageSize,
          requestedTableColumns: requestedTableColumns,
          // filter
          query: saveViewState.filter.query,
          rangeDates: saveViewState.filter.rangeDates,
          simpleSearch: saveViewState.filter.simpleSearch,
          doubleQuery: saveViewState.filter.doubleQuery,
        };

        let sortBy = requestedTableColumns.find((item) => item.order);
        if (sortBy) {
          params.sort = `[{"property": "${sortBy.columnKey}", "direction": "${sortBy.order}"}]`;
        }

        // useOrgInSidebar &&
        //   orgId &&
        //   FilterUtils.addOrgInSidebarToFilter(params, orgId);

        if (onRequestData) {
          setForceTableAction({ ACTION_TYPE: "LOADING_DATA" });

          onRequestData(
            params,
            (data) => {
              let updateData = {
                ...data,
                pageCount: data.countByFilter,
                totalItem: {
                  countByFilter: data.countByFilter,
                },
              };
              setShowEmptyData(data.countAll === 0);
              setForceTableAction({
                ...updateData,
                ACTION_TYPE: "UPDATE_DATA",
              });
            },
            (err) => {
              setForceTableAction({ ...err, ACTION_TYPE: "ERROR_DATA" });
            }
          );
        } else if (onRobustRequestData) {
          onRobustRequestData(
            params,
            (ready) => {
              if (ready) {
                console.log("ready set action to LOADING_DATA");
                setForceTableAction({ ACTION_TYPE: "LOADING_DATA" });
              } else {
                console.log("not ready, do nothing");
              }
            },
            (data) => {
              let updateData = {
                ...data,
                pageCount: data.countByFilter,
                totalItem: {
                  countByFilter: data.countByFilter,
                },
              };
              setShowEmptyData(data.countAll === 0);
              setForceTableAction({
                ...updateData,
                ACTION_TYPE: "UPDATE_DATA",
              });
            },
            (err) => {
              setForceTableAction({ ...err, ACTION_TYPE: "ERROR_DATA" });
            }
          );
        }
      } else if (isFilterValid && isMapValid) {
        let params = {
          switchMode: "MAP",
          queryObjectType: filterOptions.queryObjectType,
          searchQueryType: saveViewState.filter.searchQueryType,
          query: saveViewState.filter.query,
          requestedTableColumns: requestedMapColumns,
        };

        if (onRequestData) {
          setForceMapAction({ ACTION_TYPE: "LOADING_DATA" });

          onRequestData(
            params,
            (data) => {
              setShowEmptyData(data.countAll === 0);
              setForceMapAction({ data, ACTION_TYPE: "UPDATE_DATA" });
            },
            (err) => {
              setForceMapAction({ ...err, ACTION_TYPE: "ERROR_DATA" });
            }
          );
        } else if (onRobustRequestData) {
          onRobustRequestData(
            params,
            (ready) => {
              if (ready) {
                setForceMapAction({ ACTION_TYPE: "LOADING_DATA" });
              }
            },
            (data) => {
              setShowEmptyData(data.countAll === 0);
              setForceMapAction({ data, ACTION_TYPE: "UPDATE_DATA" });
            },
            (err) => {
              setForceMapAction({ ...err, ACTION_TYPE: "ERROR_DATA" });
            }
          );
        }
      }
    }, 300);
    return () => {
      timeout && clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterValid, isTableValid, isMapValid, saveViewState]);

  useEffect(() => {
    if (enableSwitchMode) {
      setFixedSections([
        [
          {
            id: "TABLE",
            title: language.list_key,
            isHideText: true,
            isAlwayShow: true,
            icon: <SntListIcon />,
          },
          {
            id: "MAP",
            title: language.map_key,
            isHideText: true,
            isAlwayShow: true,
            icon: <SntMapIcon />,
          },
        ],
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionClick = (id) => {
    if (id === "MAP" || id === "TABLE") {
      updateSwitchMode(id);
      return;
    }

    let optionsSettings = getOldStructureOptionSettings();
    handleActionData && handleActionData(id, saveViewState, optionsSettings);
  };

  return (
    <>
      <PageLayout
        topContent={
          <>
            {!isLoading && showPermissionWarning ? (
              <PagePermissionWarning pageName={saveViewState.pageName} />
            ) : (
              ""
            )}

            <PageHeader
              tabTitle={title}
              title={
                <>
                  {saveViewState.title || title} {isChanged ? "*" : ""}
                </>
              }
              extraTitle={extraTitle}
              renderHeaderIcon={() => {
                return (
                  <>
                    <ListSaveView
                      onResetToDefault={onResetSaveView}
                      autoSaveViewName={title}
                    />

                    <ButtonsSaveView defaultTitle={title} />
                  </>
                );
              }}
              renderRightHeader={() => {
                return (
                  <>
                    <Toolbar
                      selectedId={saveViewState.switchMode}
                      sections={sections || []}
                      fixedSections={fixedSections}
                      handleClick={handleActionClick}
                    />
                  </>
                );
              }}
            />

            {(emptyData ? !isShowEmptyData : true) && (
              <>
                {filterOptions &&
                  (filterRender ? (
                    filterRender()
                  ) : (
                    <FiltersSaveView orgId={orgId} />
                  ))}
              </>
            )}
          </>
        }
      >
        {(height) => {
          return (
            <>
              {topContentRender && <>{topContentRender()}</>}

              {(emptyData ? !isShowEmptyData : true) && (
                <>
                  {saveViewState.switchMode === "TABLE" &&
                    tableOptions &&
                    tableRender && <div> {tableRender(height)}</div>}

                  {saveViewState.switchMode === "MAP" &&
                    mapOptions &&
                    mapRender && <div> {mapRender(height)}</div>}
                </>
              )}

              {isShowEmptyData && emptyData ? emptyData : <></>}
            </>
          );
        }}
      </PageLayout>
    </>
  );
};

export default SaveViewPage;
