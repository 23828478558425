const _partnerOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "PARTNER";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["partnerName"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "commercialPartner",
        "createdAt",
        "enableRegister",
        "allowDevAccountManagement",
        "externalId",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _partnerOptions;
