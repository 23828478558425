const _deviceTagOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "DEVICE_TAG";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "organisation"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organisationName"],
      columnVisibleInOrder: [],
      visibleCols: ["name", "numAssets"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _deviceTagOptions;
