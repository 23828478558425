import { useEffect, useRef, useState } from "react";

import searchUI from "@apis/searchUIClient";
import FilterList from "@/components/AdvancedFilters/FilterList";
import { FilterLineLoader } from "@/components/AdvancedFilters/FilterStyle";

const Filters = ({
  dataOptions = {}, // {categories, queryObjectType, filtersVisibleAtInit, includeDynamicFilter, restrictedFilters }
  orgId,
  onChange,
  totalText,
  query,
  disabled = false,
  forceFilterAction,
  setForceFilterAction,
  onLoadFilter,
  isDisableAddFilterBtn,
}) => {
  const optionsSettings = useRef(null);
  const descriptors = useRef(null);

  const [filterListData, setFilterListData] = useState(null);

  const loadBasicComponents = () => {
    let _categoriesParam = null;
    if (dataOptions.categories) {
      _categoriesParam = { categories: dataOptions.categories };
    }
    let orgParam = orgId && !disabled ? `?org=${orgId}` : "";
    searchUI
      .getDescriptors(dataOptions.queryObjectType, _categoriesParam, orgParam)
      .then(({ data: _descriptors }) => {
        if (dataOptions.includeDynamicFilter === false) {
          _descriptors.forEach(
            (category) =>
              (category.searchFields = category.searchFields.filter(
                (filter) => filter.searchType !== "DYNAMIC"
              ))
          );
        }
        descriptors.current = _descriptors;

        let filtersMap = {};
        _descriptors.forEach((item) => {
          item.searchFields?.forEach((filter) => {
            filtersMap[filter.key] = filter.filterType;
          });
        });
        onLoadFilter && onLoadFilter(filtersMap);

        setFilterListData({
          queryObjectType: dataOptions.queryObjectType,
          descriptors: _descriptors,
          appliedFilterSettings: query || [],
          filtersVisibleAtInit: dataOptions.filtersVisibleAtInit,
        });
      })
      .catch((error) => {
        console.log("can not load descriptors: ", error);
      });
  };

  const onFilterChange = (data, action) => {
    onChange &&
      onChange(
        {
          searchQueryType: data.searchQueryType,
          query: data.query,
        },
        action
      );
    optionsSettings.current = data.query;
  };

  const onResetFilterData = () => {
    setFilterListData({
      ...filterListData,
      appliedFilterSettings: optionsSettings.current.map((query) => {
        delete query.filterValue;
        query.notFilter = false;
        return query;
      }),
    });

    onChange &&
      onChange(
        {
          searchQueryType: filterListData.searchQueryType,
          query: optionsSettings.current,
        },
        "RESET_FILTER"
      );
  };

  useEffect(() => {
    optionsSettings.current = query || [];

    if (!filterListData) {
      loadBasicComponents();
    } else {
      setFilterListData({
        queryObjectType: dataOptions.queryObjectType,
        descriptors: descriptors.current,
        appliedFilterSettings: query || [],
        filtersVisibleAtInit: dataOptions.filtersVisibleAtInit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      {filterListData ? (
        <>
          <FilterList
            restrictedFilters={dataOptions.restrictedFilters}
            data={filterListData}
            isVisibleAddFilterBtn={!isDisableAddFilterBtn}
            onChange={onFilterChange}
            onResetFilterData={onResetFilterData}
            disabled={disabled}
            totalElement={<span>{totalText}</span>}
            deviceCategorySuggestion={dataOptions.deviceCategorySuggestion}
            forceFilterAction={forceFilterAction}
            setForceFilterAction={setForceFilterAction}
          />
        </>
      ) : (
        <FilterLineLoader></FilterLineLoader>
      )}
    </>
  );
};

export default Filters;
