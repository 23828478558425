import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import cellularAccountClient from "@apis/cellularAccountClient.js";
import useDeleteDialog from "@widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntLink from "@/components/ReactBootstrap/SntLink";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const CellularAccountTableSaveView = () => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;
  const table = useRef();

  const allColumns = useMemo(() => {
    return [
      {
        key: "name",
        Cell: ({ cell, value }) => {
          return (
            <div>
              <SntLink
                to={`/cellular_account_manage?type=view&id=${cell.row.original.id}`}
              >
                {value}
              </SntLink>
            </div>
          );
        },
      },
      {
        key: "cellularProvider",
        Cell: ({ cell, value }) => {
          const provider = value;
          return provider ? provider : "";
        },
        cellCopyData: (data) => {
          return [data.cellularProvider || ""];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularProvider || ""}`];
        },
      },
      {
        key: "applicationKey",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "applicationSecret",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "username",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "password",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "numberOfCallsPerMinute",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(() => {
    if (permissionsMap["CELLULAR_ACCOUNT_MANAGE"]) {
      return [
        {
          key: "actions",
          title: language.actions_key,
          immovable: true,
          disableSortBy: true,
          canResize: false,
          Cell: ({ cell }) => {
            const full = cell.row.original;
            return (
              <div>
                <SntActionButton
                  title={language.edit_key}
                  to={`/cellular_account_manage?type=edit&id=${cell.row.original.id}`}
                >
                  <SntPencilIcon />
                </SntActionButton>

                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              </div>
            );
          },
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsMap]);

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      cellularAccountClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </div>
  );
};

export default CellularAccountTableSaveView;
