const _alertRuleOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "ALERT_RULE";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["rule_name", "rule_type", "rule_severity"],
      restrictedFilters: ["notifications"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["id"],
      columnVisibleInOrder: [],
      visibleCols: [
        "alertRuleSeverity",
        "alertRuleActive",
        "title",
        "alertRuleType",
        "alertNotifications",
        "actions",
      ],
      defaultSortBy: ["title", true],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _alertRuleOptions;
