import DateModeView from "@widgets/dates/DateModeView";
import moment from "moment";

const _auditLogOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "AUDIT_LOG";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["auditCategory", "entity", "authorType"],
      restrictedFilters: ["createdAt"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
      rangeDates: {
        start: moment(),
        end: moment(),
        viewMode: DateModeView.day,
        overrideDisplays: { month: false, year: false },
      },
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["id"],
      columnVisibleInOrder: [],
      visibleCols: [
        "createdAt",
        "authorName",
        "authorType",
        "auditCategory",
        "auditAction",
        "entityId",
        "entityName",
        "organizations",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _auditLogOptions;
