const _bulkOperationOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "BULK_OPERATION_REQUEST";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["operationType"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "details",
        "objectType",
        "operationType",
        "status",
        "createdAt",
        "createdBy",
        "progress",
        "object ",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
  return pageOptions;
};

export default _bulkOperationOptions;
