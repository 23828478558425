import { useCallback, useEffect, useRef, useState } from "react";
import SntDialog from "../SntDialog/SntDialog";
import { ReactSortable } from "react-sortablejs";
import SntCheckBox from "../SntCheckBox/SntCheckBox";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import SntReOrderIcon from "../Icons/SntReOrderIcon";
import SntLink from "../ReactBootstrap/SntLink";
import SntCloseIcon from "../Icons/SntCloseIcon";
import IcomoonIcon from "../Icons/IcomoonIcon";
import styled from "styled-components";
import SntGroupBy from "../Icons/SntGroupBy";
import SntUnGroup from "../Icons/SntUnGroup";
import SntSortZA from "../Icons/SntSortZA";
import SntSortAZ from "../Icons/SntSortAZ";
import { VerticalLayout } from "../ReactBootstrap/FormValidation";
import SntRadio from "../SntRadio/SntRadio";
import {
  bucketTypeConstant,
  supportedGroupTypeConstant,
} from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";
import SntSelect from "@wrappers/ReactSelect/SntSelect.js";
import { getGroupColumnData } from "@/cleanup/utils/tableUtils";
import SntButton from "@/cleanup/widgets/forms/SntButton";

const ConfigLayout = styled.table`
  width: 100%;
  margin-bottom: 0px;

  tr td:first-child {
    width: 30%;
    padding-right: 1rem;
  }

  .list-group {
    width: 100%;
  }

  .list-group-item-selected {
    background: #eff3f4;
    padding: 0.3rem 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 4px;
    &.group {
      background: white;
      border: 1px solid var(--bs-list-group-border-color);
    }
  }
  .list-group-item-unselected {
    border-radius: 0;
    border-bottom: 1px solid var(--bs-list-group-border-color);
  }

  .list-group-item-selected .sortIconHover {
    opacity: 0;
  }

  .list-group-item-selected:hover {
    .sortIconHover {
      opacity: 1;
    }
  }

  .column-scroll {
    overflow: hidden auto;
  }

  .hide-long-title {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

export const SntConfigColumns = ({
                                   dataFilter,
                                   isShowConfigDialog,
                                   onDone,
                                   onCancel,
                                   isDisabbledGroup,
                                   isDisabbledUnGroupIcon = false,
                                   optionsSettings,
                                   defaultSortBy,
                                   isHiddenSupportedMetrics,
                                 }) => {
  const language = useSelector((state) => state.language);
  const { isMobile } = useSelector((state) => state.all);
  const loginInfo = useSelector((state) => state.user);
  const { features } = loginInfo;

  const {
    filter,
    allColumns,
    defaultVisibleColumnKeys,
    setColumnsSetting,
  } = dataFilter;

  const [configState, setConfigState] = useState(null);
  const [columnCategories, setColumnCategories] = useState([]);
  const [columnsByKey, setColumnsByKey] = useState([]);

  const [sortBy, setSortBy] = useState();
  const [searchColumn, setSearchColumn] = useState("");

  const [filterQuery, setFilterQuery] = useState([]);
  useEffect(() => {
    if (optionsSettings?.query) {
      setFilterQuery(optionsSettings?.query);
    }
  }, [optionsSettings]);

  const initializeCategories = useCallback((allColumns) => {
    let groupByKey = {},
        groupList = [],
        columnMap = {};

    for (let i = 0; i < allColumns.length; i++) {
      let obj = allColumns[i];
      // skip immovables
      if (obj.immovable) {
        continue;
      }
      columnMap[obj.columnKey] = obj;

      let key = obj.categoryId || obj.category;

      let groupItem = groupByKey[key];
      if (!groupItem) {
        groupItem = {
          key: key,
          columns: [],
          groupColumns: [],
          category: obj.category,
        };
        groupByKey[key] = groupItem;
        groupList.push(groupItem);
      }
      groupItem.columns.push(obj);
      if (!obj.disabledGroupColumn) {
        groupItem.groupColumns.push(obj);
      }
    }
    setColumnCategories(
        groupList.filter((item) => {
          return item.columns.length > 0;
        })
    );

    setColumnsByKey(columnMap);
  }, []);

  const initializeSelection = useCallback((filterColumns, columnsByKey) => {
    let selectedList = [];

    let selectedGroup = [],
        selectedBucket = null,
        selectedAggregateMetrics = [],
        bucketConfig = {};

    let { groupColumn, metricData } = getGroupColumnData(filterColumns);

    if (groupColumn) {
      let columnKey = groupColumn.columnKey;
      let obj = columnsByKey[columnKey];
      if (obj) {
        selectedGroup = [transformItemByKey(columnKey, obj)]; // later, support multiple group
      }

      metricData.forEach((metric) => {
        let metricColumn = filterColumns.find(
            (col) =>
                col.aggregateColumn === metric.aggregateColumn &&
                !col.aggregationType
        );
        selectedAggregateMetrics.push(
            getAggregateMetricsColumn(
                metricColumn.columnKey + "." + metric.aggregationType.toLowerCase(),
                metricColumn,
                language
            )
        );
      });

      selectedBucket = groupColumn.bucketType;
      bucketConfig = groupColumn.bucketConfig || {};
    }

    // dont show columns in group
    let columns = filterColumns.filter((col) => !col.aggregationType);

    for (let i = 0; i < columns.length; i++) {
      let columnKey = columns[i].columnKey;
      let obj = columnsByKey[columnKey];

      // should not happen !
      if (!obj) continue;
      selectedList.push(transformItemByKey(columnKey, obj));
    }

    setConfigState({
      selectedColumns: selectedList,
      selectedGroup: selectedGroup,
      selectedBucket: selectedBucket,
      bucketConfig: bucketConfig,
      selectedAggregateMetrics: selectedAggregateMetrics,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeCategories(allColumns);
  }, [allColumns, initializeCategories]);

  useEffect(() => {
    initializeSelection(filter.columns, columnsByKey);
  }, [filter.columns, columnsByKey, initializeSelection]);

  useEffect(() => {
    setSearchColumn("");
    if (filter && filter.sortBy) {
      setSortBy({
        key: filter.sortBy.column.sortKey,
        desc: filter.sortBy.desc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowConfigDialog]);

  const onResetDefault = () => {
    if (defaultVisibleColumnKeys) {
      let cols = defaultVisibleColumnKeys
          .map((key) => {
            let obj = columnsByKey[key];
            if (!obj) {
              return null;
            }

            return transformItemByKey(key, obj);
          })
          .filter((item) => item !== null);

      setConfigState({
        ...configState,
        selectedColumns: cols,
        selectedGroup: [],
        selectedBucket: null,
        bucketConfig: null,
      });

      if (defaultSortBy?.length) {
        setSortBy({ key: defaultSortBy[0], desc: defaultSortBy[1] });
      } else {
        setSortBy({ key: cols[0].key, desc: false });
      }
    }
  };

  if (!configState) return <></>;

  return (
      <SntConfigColumns1
          isShowConfigDialog={isShowConfigDialog}
          onDone={onDone}
          onCancel={onCancel}
          isDisabbledGroup={isDisabbledGroup}
          isDisabbledUnGroupIcon={isDisabbledUnGroupIcon}
          isMobile={isMobile}
          configState={configState}
          onConfigStateChange={(data) => {
            setConfigState({ ...configState, ...data });
          }}
          filterQuery={filterQuery} // for checking
          columnCategories={columnCategories}
          columnsByKey={columnsByKey}
          setColumnsSetting={setColumnsSetting}
          language={language}
          sortBy={sortBy}
          onSortByChange={setSortBy}
          searchColumn={searchColumn}
          onSearchChange={setSearchColumn}
          isAggregate={features["search_aggregate"]}
          isSupportedMetrics={
            isHiddenSupportedMetrics === true
                ? false
                : features["table_advanced_aggregations"]
          }
          onResetDefault={onResetDefault}
      />
  );
};

export const SntConfigColumns1 = ({
                                    isShowConfigDialog,
                                    onDone,
                                    onCancel,

                                    configState,
                                    onConfigStateChange,

                                    isDisabbledGroup,
                                    isDisabbledUnGroupIcon = false,

                                    columnCategories,
                                    columnsByKey,

                                    filterQuery,
                                    setColumnsSetting,

                                    language,
                                    isMobile,

                                    sortBy,
                                    onSortByChange,
                                    searchColumn,
                                    onSearchChange,
                                    isAggregate,
                                    isSupportedMetrics,
                                    onResetDefault,
                                  }) => {
  const ref = useRef();

  const [buckets] = useState([
    {
      id: bucketTypeConstant.DAY,
      label: language.by_day_key,
    },
    {
      id: bucketTypeConstant.WEEK,
      label: language.by_week_key,
    },
    {
      id: bucketTypeConstant.MONTH,
      label: language.by_month_key,
    },
    {
      id: bucketTypeConstant.YEAR,
      label: language.by_year_key,
    },
  ]);

  const {
    selectedColumns,
    selectedGroup,
    selectedBucket,
    bucketConfig,
    selectedAggregateMetrics,
  } = configState;

  const onSaveSettingsPopup = () => {
    let list = selectedColumns.map((item) => item.key);
    let groupKey = "",
        metrics = [];

    if (selectedGroup.length > 0) {
      groupKey = selectedGroup[0].key;
      if (selectedGroup[0].supportedGroupType === "NUMERIC_BUCKET") {
        if (
            selectedBucket === bucketTypeConstant.WIDTH &&
            (!bucketConfig.low ||
                !bucketConfig.high ||
                !bucketConfig.division ||
                bucketConfig.low > bucketConfig.high)
        ) {
          onBucketConfigChange({ ...bucketConfig, isSubmited: true });
          return;
        }
      }
      list = [groupKey, ...list];
      metrics = selectedAggregateMetrics.map(
          (metric) =>
              metric.columnMetricKey + "." + metric.aggregationType.toLowerCase()
      );
    }
    setColumnsSetting(
        list,
        { key: sortBy.key, desc: sortBy.desc },
        groupKey,
        selectedBucket,
        bucketConfig,
        [...new Set(metrics)]
    );

    onDone();
  };

  const onCloseDialog = () => {
    onCancel();
  };

  const onSelectedColumn = (key) => {
    let obj = columnsByKey[key];
    if (selectedColumns.length === 0) {
      // if current selectedColumn length = 0, reset sort
      onSortByChange({ ...sortBy, key: key });
    }
    onConfigStateChange({
      selectedColumns: [...selectedColumns, transformItemByKey(key, obj)],
    });
  };
  const onUnSelectedColumn = (key) => {
    const list = selectedColumns.filter((_key) => _key.key !== key);
    onConfigStateChange({ selectedColumns: list });

    // remove current key has sort, should reset sort
    if (sortBy && sortBy.key === key && list.length) {
      onSortByChange({ ...sortBy, key: list[0].key });
    }
  };

  const onSelectedGroup = (key) => {
    let obj = columnsByKey[key];

    let selectedBucket = null;
    if (obj.supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET) {
      selectedBucket = bucketTypeConstant.DAY;
    } else if (
        obj.supportedGroupType === supportedGroupTypeConstant.NUMERIC_BUCKET
    ) {
      selectedBucket = bucketTypeConstant.AUTO_WIDTH;
    } else if (
        obj.supportedGroupType === supportedGroupTypeConstant.TAG_BASED
    ) {
      selectedBucket = bucketTypeConstant.TAGS_FULL;
    }

    let metrics = selectedAggregateMetrics;
    if (!metrics.length || !isSupportedMetrics) {
      metrics = obj.supportedAggregateMetrics.map((metric) =>
          getAggregateMetricsColumn(
              key + "." + metric.toLowerCase(),
              obj,
              language
          )
      );
    }

    onConfigStateChange({
      selectedGroup: [transformItemByKey(key, obj)],
      selectedAggregateMetrics: metrics,
      selectedBucket: selectedBucket,
      bucketConfig: {},
    });
    onSortByChange({ ...sortBy, key: key });
  };
  const onUnSelectedGroup = (key) => {
    onSortByChange({ ...sortBy, key: selectedGroup[0].key });
    onConfigStateChange({
      selectedGroup: [],
      selectedAggregateMetrics: [],
      selectedBucket: null,
      bucketConfig: {},
    });
  };

  const renderAvailableSection = () => {
    return availableSection(
        columnCategories,
        selectedColumns,
        onSelectedColumn
    );
  };

  const onSelectedBucketChange = (selectedBucket) => {
    onConfigStateChange({ selectedBucket });
  };

  const onBucketConfigChange = (bucketConfig) => {
    onConfigStateChange({ bucketConfig });
  };

  const onSelectedMetricColumn = (key) => {
    let obj = columnsByKey[key];
    let firstMetric = obj.supportedAggregateMetrics[0];
    let metric = getAggregateMetricsColumn(
        key + "." + firstMetric.toLowerCase(),
        obj,
        language
    );

    onConfigStateChange({
      selectedAggregateMetrics: [...selectedAggregateMetrics, metric],
    });
  };

  const onUnSelectedMetricColumn = (key) => {
    const list = selectedAggregateMetrics.filter((_key) => _key.key !== key);
    onConfigStateChange({
      selectedAggregateMetrics: list,
    });

    // remove current key has sort, should reset sort
    if (sortBy && sortBy.key === key && list.length) {
      onSortByChange({ ...sortBy, key: list[0].key });
    }
  };

  const onMetricTypeChanged = (index, item, data) => {
    const updatedList = selectedAggregateMetrics.map((metric, idx) => {
      if (idx === index) {
        let updateKey = metric.columnMetricKey + "." + data.value.toLowerCase();

        if (metric.key === sortBy.key) {
          onSortByChange({ ...sortBy, key: updateKey });
        }
        return {
          ...metric,
          aggregationType: data.value,
          key: updateKey,
          sortKey: updateKey,
        };
      }
      return metric;
    });
    onConfigStateChange({
      selectedAggregateMetrics: updatedList,
    });
  };

  const availableSection = (categories, selected, onClick) => {
    return (
        <div
            className="column-scroll snt-scroll"
            style={{ maxHeight: isMobile ? "40vh" : "70vh" }}
        >
          {categories.map((item, index) => {
            let _showGroup = false;

            const _listItems = item.columns.map((obj, idx) => {
              let _title = obj.settingTitle || obj.title || "";
              let isShowItem = false;
              if (
                  _title.toLowerCase().indexOf(searchColumn.toLowerCase()) > -1 &&
                  selected.filter((item) => item.key === obj.columnKey).length === 0
              ) {
                _showGroup = true;
                isShowItem = true;
              }

              if (!isShowItem) return <span key={idx}></span>;
              return (
                  <li
                      key={idx}
                      className="list-group-item list-group-item-unselected"
                  >
                    <Row>
                      <Col xs={6} xl={3}>
                        <SntCheckBox
                            checked={false}
                            onChange={() => {
                              onClick(obj.columnKey);
                            }}
                            label={
                              <>
                          <span className="ps-1">
                            {obj.settingTitle || obj.title}
                          </span>
                              </>
                            }
                        />
                      </Col>
                      <Col xs={6} xl={8}>
                        {obj.description &&
                            obj.description !== (obj.settingTitle || obj.title) && (
                                <div
                                    className="d-flex"
                                    style={{
                                      color: "#424B59",
                                    }}
                                >
                                  <div style={{ minWidth: 24 }}>
                                    <IcomoonIcon icon="info" size={18} />
                                  </div>
                                  <div className="flex-grow-1">{obj.description}</div>
                                </div>
                            )}
                      </Col>
                    </Row>
                  </li>
              );
            });

            return (
                <div
                    key={index}
                    className="mb-3"
                    style={{ display: _showGroup ? "block" : "none" }}
                >
                  {item.category && (
                      <label className="fw-bold">{item.category}</label>
                  )}
                  <ul className="list-group caterogy-group">{_listItems}</ul>
                </div>
            );
          })}
        </div>
    );
  };

  const renderSelectedSection = () => {
    return columnsSelectedSection();
  };

  const columnsSelectedSection = () => {
    return (
        <div
            className="column-scroll snt-scroll"
            style={{ maxHeight: isMobile ? "40vh" : "70vh", paddingBottom: 15 }}
        >
          <Row>
            <Col>
              <span className="fw-bold">{selectedColumns.length}</span>{" "}
              {language.columns_selected_key}
            </Col>
            <Col>
              <SntLink
                  className="float-end"
                  onClick={(e) => {
                    onResetDefault();
                  }}
              >
                {language.reset_to_defaults_key}
              </SntLink>
            </Col>
          </Row>
          <div className="mb-2">{language.columns_selected_info_key}</div>
          {groupSelectedList(selectedGroup, onUnSelectedGroup)}

          {selectedGroup.length ? (
              <>
                <div className="mb-2 fw-bold">{"Metrics"}</div>
                <div className="mb-4">
                  {metricSection(
                      selectedAggregateMetrics,
                      (data) => {
                        onConfigStateChange({ selectedAggregateMetrics: data });
                      },
                      onUnSelectedMetricColumn
                  )}
                </div>

                <div
                    className="d-flex mb-2"
                    style={{
                      color: "#424B59",
                    }}
                >
                  <div style={{ minWidth: 24 }}>
                    <IcomoonIcon icon="info" size={18} />
                  </div>
                  <div className="flex-grow-1">
                    {language.columns_are_not_visible_info_key}
                  </div>
                </div>
              </>
          ) : (
              <></>
          )}

          {selectedSection(
              selectedColumns,
              (data) => {
                onConfigStateChange({ selectedColumns: data });
              },
              onUnSelectedColumn
          )}
        </div>
    );
  };

  const selectedSection = (selected, setSelected, onClick) => {
    return (
        <div
            className="column-scroll snt-scroll"
            style={{ maxHeight: isMobile ? "40vh" : "70vh" }}
        >
          <ReactSortable
              animation={150}
              list={selected}
              setList={setSelected}
              tag="ul"
              className="list-group caterogy-group"
          >
            {selected.map((item, index) => {
              return (
                  <li
                      key={index}
                      className={`list-group-item list-group-item-selected`}
                  >
                    <Row>
                      <Col xs={10} md={7}>
                        <div>{item.Header}</div>
                      </Col>
                      <Col xs={2} md={5}>
                        <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "1%",
                              transform: "translate(0%, -50%)",
                            }}
                        >
                          {!item.disableSortBy && !selectedGroup.length && (
                              <>
                                {sortBy.key === item.sortKey ? (
                                    sortBy.desc ? (
                                        <SntSortZA
                                            className="me-2"
                                            onClick={() => {
                                              onSortByChange({ ...sortBy, desc: false });
                                            }}
                                        />
                                    ) : (
                                        <SntSortAZ
                                            className="me-2"
                                            onClick={() => {
                                              onSortByChange({ ...sortBy, desc: true });
                                            }}
                                        />
                                    )
                                ) : (
                                    <SntSortAZ
                                        className="me-2 sortIconHover"
                                        onClick={() => {
                                          onSortByChange({
                                            key: item.sortKey,
                                            desc: false,
                                          });
                                        }}
                                    />
                                )}
                              </>
                          )}
                          {isAggregate && !isDisabbledGroup && (
                              <>
                                {isSupportedMetrics && selectedGroup.length ? (
                                    <SntButton
                                        title={language.add_to_metrics}
                                        size="sm"
                                        className={"me-2"}
                                        text={"f(x)"}
                                        style={{ padding: "0.15rem" }}
                                        onClick={() => {
                                          onSelectedMetricColumn(item.key);
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                <SntGroupBy
                                    className={"me-2"}
                                    title={language.group_on_column}
                                    style={{
                                      visibility:
                                          item.disabledGroupColumn ||
                                          (selectedGroup.length &&
                                              selectedGroup[0].key === item.key)
                                              ? "hidden"
                                              : "unset",
                                    }}
                                    onClick={() => {
                                      !item.disabledGroupColumn &&
                                      onSelectedGroup(item.key);
                                    }}
                                />
                              </>
                          )}
                          <IcomoonIcon
                              style={{
                                visibility: !item.description ? "hidden" : "unset",
                              }}
                              icon="info"
                              className={"me-2"}
                              size={18}
                              title={item.description}
                          />
                          <SntReOrderIcon
                              title={language.drag_drop_order_key}
                              className={"me-2"}
                              style={{ color: "#b9c0ca" }}
                          />
                          <SntCloseIcon
                              title={language.remove_column_key}
                              className={"me-2"}
                              onClick={() => {
                                onClick(item.key);
                              }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </li>
              );
            })}
          </ReactSortable>
        </div>
    );
  };

  const metricSection = (selected, setSelected, onClick) => {
    return (
        <div
            className="column-scroll snt-scroll"
            style={{ maxHeight: isMobile ? "40vh" : "70vh" }}
        >
          <ReactSortable
              animation={150}
              list={selected}
              setList={setSelected}
              tag="ul"
              className="list-group caterogy-group"
          >
            {selected.map((item, index) => {
              return (
                  <li
                      key={index}
                      className={`list-group-item list-group-item-selected`}
                  >
                    <Row>
                      <Col xs={10} md={10}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0" style={{ width: 30 }}>
                            {"f(x)"}
                          </div>
                          <div
                              className="flex-shrink-0 pe-2"
                              style={{ width: 110 }}
                          >
                            <SntSelect
                                value={item.options.filter(
                                    (option) => option.value === item.aggregationType
                                )}
                                options={item.options}
                                isDisabled={!isSupportedMetrics}
                                onChange={(data) => {
                                  onMetricTypeChanged(index, item, data);
                                }}
                            />
                          </div>
                          <div
                              className="flex-grow-1 text-truncate pe-2"
                              title={item.Header}
                          >
                            {item.Header}
                          </div>
                        </div>
                      </Col>
                      <Col xs={2} md={2}>
                        <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "1%",
                              transform: "translate(0%, -50%)",
                            }}
                        >
                          {!item.disableSortBy && (
                              <>
                                {sortBy.key === item.sortKey ? (
                                    sortBy.desc ? (
                                        <SntSortZA
                                            className="me-2"
                                            onClick={() => {
                                              onSortByChange({ ...sortBy, desc: false });
                                            }}
                                        />
                                    ) : (
                                        <SntSortAZ
                                            className="me-2"
                                            onClick={() => {
                                              onSortByChange({ ...sortBy, desc: true });
                                            }}
                                        />
                                    )
                                ) : (
                                    <SntSortAZ
                                        className="me-2 sortIconHover"
                                        onClick={() => {
                                          onSortByChange({
                                            key: item.sortKey,
                                            desc: false,
                                          });
                                        }}
                                    />
                                )}
                              </>
                          )}
                          {selected.length > 1 ? (
                              <>
                                <SntReOrderIcon
                                    title={language.drag_drop_order_key}
                                    className={"me-2"}
                                    style={{ color: "#b9c0ca" }}
                                />
                                <SntCloseIcon
                                    title={language.remove_column_key}
                                    className={"me-2"}
                                    onClick={() => {
                                      onClick(item.key);
                                    }}
                                />
                              </>
                          ) : (
                              <></>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </li>
              );
            })}
          </ReactSortable>
        </div>
    );
  };

  const getValidLowClass = () => {
    if (
        !bucketConfig.isSubmited &&
        !Object.keys(bucketConfig).includes("low")
    ) {
      return "";
    }
    if (
        !bucketConfig.low ||
        (bucketConfig.high && bucketConfig.low > bucketConfig.high)
    ) {
      return "is-invalid";
    }
    return "is-valid";
  };

  const getValidHighClass = () => {
    if (
        !bucketConfig.isSubmited &&
        !Object.keys(bucketConfig).includes("high")
    ) {
      return "";
    }
    if (
        !bucketConfig.high ||
        (bucketConfig.low && bucketConfig.low > bucketConfig.high)
    ) {
      return "is-invalid";
    }
    return "is-valid";
  };

  const getValidDivisionClass = () => {
    if (
        !bucketConfig.isSubmited &&
        !Object.keys(bucketConfig).includes("division")
    ) {
      return "";
    }
    if (!bucketConfig.division) {
      return "is-invalid";
    }
    return "is-valid";
  };

  const getSupportedGroupUnits = (column) => {
    let results = [];
    if (
        column &&
        column.supportedGroupUnits &&
        column.supportedGroupUnits.length > 0
    ) {
      column.supportedGroupUnits.forEach((unit) =>
          results.push({ value: unit, label: unit })
      );
    }

    return results;
  };

  const groupSelectedList = (selected, onClick) => {
    return (
        <ul className="list-group caterogy-group">
          {selected.map((item, index) => {
            let supportedGroupUnits = getSupportedGroupUnits(item);
            return (
                <div key={index}>
                  <li className="list-group-item list-group-item-selected group">
                    <Row>
                      <Col xs={10} md={7}>
                        <div>
                          {item.Header}{" "}
                          {!item.disabledGroupColumn && (
                              <span className="fw-bold"> - grouped</span>
                          )}
                        </div>
                      </Col>
                      <Col xs={2} md={5}>
                        <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "1%",
                              transform: "translate(0%, -50%)",
                            }}
                        >
                          <>
                            {!item.disableSortBy && (
                                <>
                                  {sortBy.key === item.sortKey ? (
                                      sortBy.desc ? (
                                          <SntSortZA
                                              className="me-2"
                                              onClick={() => {
                                                onSortByChange({ ...sortBy, desc: false });
                                              }}
                                          />
                                      ) : (
                                          <SntSortAZ
                                              className="me-2"
                                              onClick={() => {
                                                onSortByChange({ ...sortBy, desc: true });
                                              }}
                                          />
                                      )
                                  ) : (
                                      <SntSortAZ
                                          className="me-2 sortIconHover"
                                          onClick={() => {
                                            onSortByChange({
                                              key: item.sortKey,
                                              desc: false,
                                            });
                                          }}
                                      />
                                  )}
                                </>
                            )}
                            {!isDisabbledUnGroupIcon && (
                                <SntUnGroup
                                    title={language.ungroup_on_column}
                                    className={"me-2"}
                                    style={{
                                      visibility: item.disabledGroupColumn
                                          ? "hidden"
                                          : "unset",
                                    }}
                                    onClick={() => {
                                      onClick(item.key);
                                    }}
                                />
                            )}
                          </>

                          <IcomoonIcon
                              style={{
                                visibility: !item.description ? "hidden" : "unset",
                              }}
                              icon="info"
                              className={"me-2"}
                              size={18}
                              title={item.description}
                          />
                        </div>
                      </Col>
                    </Row>
                  </li>
                  {item.supportedGroupType ===
                      supportedGroupTypeConstant.DATE_BUCKET && (
                          <li className="list-group-item list-group-item-selected group">
                            <div>{language.select_time_window_key}</div>
                            <VerticalLayout>
                              {buckets.map((bucket) => {
                                return (
                                    <SntRadio
                                        key={bucket.id}
                                        value={bucket.id}
                                        selectedValue={selectedBucket}
                                        onChange={() => {
                                          onSelectedBucketChange(bucket.id);
                                        }}
                                        label={bucket.label}
                                    />
                                );
                              })}
                            </VerticalLayout>
                          </li>
                      )}

                  {item.supportedGroupType ===
                      supportedGroupTypeConstant.NUMERIC_BUCKET && (
                          <li className="list-group-item list-group-item-selected group">
                            <div>{language.choose_histogram_type}</div>
                            <VerticalLayout>
                              <SntRadio
                                  key={bucketTypeConstant.AUTO_WIDTH}
                                  value={bucketTypeConstant.AUTO_WIDTH}
                                  selectedValue={selectedBucket}
                                  onChange={() => {
                                    onSelectedBucketChange(bucketTypeConstant.AUTO_WIDTH);
                                  }}
                                  label={language.automatic_key}
                              />
                              <SntRadio
                                  key={bucketTypeConstant.WIDTH}
                                  value={bucketTypeConstant.WIDTH}
                                  selectedValue={selectedBucket}
                                  onChange={() => {
                                    onSelectedBucketChange(bucketTypeConstant.WIDTH);
                                  }}
                                  label={language.manual}
                              />
                            </VerticalLayout>
                            {selectedBucket === bucketTypeConstant.WIDTH && (
                                <div>
                                  {item &&
                                      item.supportedGroupUnits &&
                                      item.supportedGroupUnits.length > 0 && (
                                          <Row>
                                            <Col xs="5" lg="5">
                                              <Form.Label
                                                  style={{
                                                    fontWeight: "normal",
                                                    paddingLeft: "32px",
                                                  }}
                                                  className="mt-2"
                                              >
                                                {language.units_key}
                                              </Form.Label>
                                            </Col>
                                            <Col xs="7" lg="7">
                                              <SntSelect
                                                  value={supportedGroupUnits.filter(
                                                      (option) =>
                                                          option.value ===
                                                          (bucketConfig.unit ||
                                                              supportedGroupUnits[0].value)
                                                  )}
                                                  options={supportedGroupUnits}
                                                  onChange={(unit) => {
                                                    onBucketConfigChange({
                                                      ...bucketConfig,
                                                      unit: unit.value,
                                                    });
                                                  }}
                                              />
                                            </Col>
                                          </Row>
                                      )}
                                  <Row>
                                    <Col xs="5" lg="5">
                                      <Form.Label
                                          style={{
                                            fontWeight: "normal",
                                            paddingLeft: "32px",
                                          }}
                                          className="mt-2"
                                      >
                                        {language.minimum_value_key}
                                      </Form.Label>
                                    </Col>
                                    <Col xs="7" lg="7">
                                      <Form.Control
                                          type="number"
                                          min="0"
                                          value={bucketConfig.low}
                                          onChange={(e) =>
                                              onBucketConfigChange({
                                                ...bucketConfig,
                                                low: e.target.value,
                                              })
                                          }
                                          className={`${getValidLowClass()}`}
                                          size="md"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        The min value should be less than or equal max value
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="5" lg="5">
                                      <Form.Label
                                          style={{
                                            fontWeight: "normal",
                                            paddingLeft: "32px",
                                          }}
                                          className="mt-2"
                                      >
                                        {language.maximum_value_key}
                                      </Form.Label>
                                    </Col>
                                    <Col xs="7" lg="7">
                                      <Form.Control
                                          type="number"
                                          min="0"
                                          value={bucketConfig.high}
                                          onChange={(e) =>
                                              onBucketConfigChange({
                                                ...bucketConfig,
                                                high: e.target.value,
                                              })
                                          }
                                          className={`${getValidHighClass()}`}
                                          size="md"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        The max value should be greater than or equal min
                                        value
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="5" lg="5">
                                      <Form.Label
                                          style={{
                                            fontWeight: "normal",
                                            paddingLeft: "32px",
                                          }}
                                          className="mt-2"
                                      >
                                        {language.divisions_up_to_20_key}
                                      </Form.Label>
                                    </Col>
                                    <Col xs="7" lg="7">
                                      <Form.Control
                                          type="number"
                                          min={1}
                                          max={20}
                                          value={bucketConfig.division}
                                          onChange={(e) => {
                                            let newValue = Math.max(e.target.value, 1);
                                            newValue = Math.min(newValue, 20);
                                            onBucketConfigChange({
                                              ...bucketConfig,
                                              division: newValue,
                                            });
                                          }}
                                          size="md"
                                          className={`${getValidDivisionClass()}`}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        This field is required
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </div>
                            )}
                          </li>
                      )}

                  {filterQuery.find(
                          (q) =>
                              q.filterKey === item?.redirectFilter?.key &&
                              q.filterValue?.selectedValues?.length
                      ) &&
                      item.supportedGroupType ===
                      supportedGroupTypeConstant.TAG_BASED && (
                          <li className="list-group-item list-group-item-selected group">
                            <div>{language.use_all_tag_when_grouping}</div>
                            <VerticalLayout>
                              <SntRadio
                                  key={bucketTypeConstant.TAGS_FULL}
                                  value={bucketTypeConstant.TAGS_FULL}
                                  selectedValue={selectedBucket}
                                  onChange={() => {
                                    onSelectedBucketChange(bucketTypeConstant.TAGS_FULL);
                                  }}
                                  label={language.yes_default}
                              />
                              <SntRadio
                                  key={bucketTypeConstant.TAGS_FILTERED}
                                  value={bucketTypeConstant.TAGS_FILTERED}
                                  selectedValue={selectedBucket}
                                  onChange={() => {
                                    onSelectedBucketChange(
                                        bucketTypeConstant.TAGS_FILTERED
                                    );
                                  }}
                                  label={language.no_only_filtered}
                              />
                            </VerticalLayout>
                          </li>
                      )}
                </div>
            );
          })}
        </ul>
    );
  };

  return (
      <SntDialog
          isShow={isShowConfigDialog}
          onSave={onSaveSettingsPopup}
          onClose={onCloseDialog}
          onHide={onSaveSettingsPopup}
          title={language.config_columns_key}
          saveTxt={language.ok_key}
          closeTxt={language.cancel_key}
          refWrapper={ref}
          customModalClassname={"snt-config-column-modal"}
          bodyClass={"p-0"}
      >
        {() => (
            <>
              <ConfigLayout className="table table-bordered">
                {isMobile ? (
                    <tbody>
                    <tr>
                      <td className="fw-bold">
                        {selectedGroup.length > 0
                            ? language.group_by_key
                            : language.visible_columns}
                      </td>
                    </tr>
                    <tr>
                      <td>{renderSelectedSection()}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">{language.available_columns_key}</td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Control
                            placeholder={language.search_column_key}
                            value={searchColumn}
                            onChange={(e) => onSearchChange(e.target.value)}
                            size="md"
                            className="mb-2"
                        />
                        {renderAvailableSection()}
                      </td>
                    </tr>
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                      <td className="fw-bold">
                        {selectedGroup.length > 0
                            ? language.group_by_key
                            : language.visible_columns}
                      </td>
                      <td className="fw-bold">{language.available_columns_key}</td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>
                        {renderSelectedSection()}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <Form.Control
                            placeholder={language.search_column_key}
                            value={searchColumn}
                            onChange={(e) => onSearchChange(e.target.value)}
                            size="md"
                            className="mb-2"
                        />
                        {renderAvailableSection()}
                      </td>
                    </tr>
                    </tbody>
                )}
              </ConfigLayout>
            </>
        )}
      </SntDialog>
  );
};

const getAggregateMetricsColumn = (key, groupColumn, language) => {
  let column = {
    ...groupColumn,
    columnMetricKey: groupColumn.columnKey,
    key: key,
    sortKey: key,
    disabledGroupColumn: true,
    aggregateColumn: groupColumn.aggregateColumn,
    options: groupColumn.supportedAggregateMetrics.map((key) => ({
      value: key,
      label: key,
    })),
  };
  if (key.includes(".count")) {
    return {
      ...column,
      aggregationType: "COUNT",
    };
  } else if (key.includes(".min")) {
    return {
      ...column,
      aggregationType: "MIN",
    };
  } else if (key.includes(".max")) {
    return {
      ...column,
      aggregationType: "MAX",
    };
  } else if (key.includes(".avg")) {
    return {
      ...column,
      aggregationType: "AVG",
    };
  } else {
    return column;
  }
};

const transformItemByKey = (columnKey, obj) => {
  return {
    ...obj,
    key: columnKey,
    Header: obj.Header || obj.title || obj.description,
    disabledGroupColumn: obj.disabledGroupColumn,
    description:
        obj.description !== (obj.settingTitle || obj.title)
            ? obj.description
            : null,
  };
};

export default SntConfigColumns;