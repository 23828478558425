/* eslint-disable no-unused-vars */
import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import SntPanel from "@/components/Panel/SntPanel";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import RMAStatusLabel from "./RMAStatusLabel";

import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import PDFJSWorker from "pdfjs-dist/build/pdf.worker.js?url";

const RMADeclarationViewDetails = ({ reload }) => {
  const language = useSelector((state) => state.language);

  const location = useLocation();
  let { id, type: routeType } = queryString.parse(location.search);
  const [rmaDeclaration, setRmaDeclaration] = useState({
    zendeskId: "",
    linkToZendesk: "",
    description: "",
    internalDescription: "",
    status: "NEW",
    monitoredDevices: {
      selectType: "ALL",
      selectedIds: [],
    },
    deviceSerials: [],
  });

  const [file, setFile] = useState();

  useEffect(() => {
    if (id) {
      setFile(rmaDeclarationClient.getPdfFile(id));
    }
  }, [id, reload]);

  useEffect(() => {
    if (id) {
      rmaDeclarationClient.getById(id).then(({ data }) => {
        let d = { ...data };
        setRmaDeclaration(d);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload, routeType]);

  return (
    <>
      <PageHeader title={language.device_return} />

      <SntPanel title={language.return_details}>
        <FormFullScreenLayout
          label={language.zendesk_support_id}
          rightRender={() => (
            <a
              href={rmaDeclaration.linkToZendesk}
              target="_blank"
              rel="noreferrer"
            >
              {rmaDeclaration.zendeskId}
            </a>
          )}
        />
        <FormFullScreenLayout
          label={language.status_key}
          rightRender={() => (
            <RMAStatusLabel value={rmaDeclaration.status} other="" />
          )}
        />

        <FormFullScreenLayout
          label={language.description_key}
          rightRender={() => rmaDeclaration.description}
        />

        <FormFullScreenLayout
          label={language.device_return}
          rightRender={() => rmaDeclaration.deviceSerials.join()}
        />
      </SntPanel>

      <SntPanel title={language.return_sheet}>
        <SntInfoPanel>
          <p>
            {language.return_sheet_desc_1}
            <br />
            {language.return_sheet_desc_2}
            <br />
            {language.return_sheet_desc_3}
          </p>
        </SntInfoPanel>

        {file ? (
          <div className="d-flex justify-content-center">
            <Worker workerUrl={PDFJSWorker}>
              <div style={{ width: 800, height: 800 }}>
                <Viewer fileUrl={file} />
              </div>
            </Worker>
          </div>
        ) : (
          ""
        )}
      </SntPanel>
    </>
  );
};

export default RMADeclarationViewDetails;
