import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import RMAStatusLabel from "./RMAStatusLabel";
import SntDownloadButton from "@/components/ReactBootstrap/SntDownloadButton";
import SntText from "@/components/ReactBootstrap/SntText";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const RMADeclarationTableSaveView = () => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;
  const table = useRef();

  const allColumns = useMemo(
    () => [
      {
        key: "zendeskId",
        title: language.zendesk_support_id,
        Cell: ({ cell, value }) => {
          return (
            <a
              href={cell.row.original.linkToZendesk}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
      {
        key: "status",
        title: language.status_key,
        Cell: ({ cell, value }) => {
          return <RMAStatusLabel value={value} other="" />;
        },
      },
      {
        key: "deviceSerials",
        title: language.number_of_trackers_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value?.length || "";
        },
        cellCopyData: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
        cellFilterValue: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
      },
      {
        key: "ownedByOrg",
        title: language.organizations_key,
        Cell: ({ cell, value }) => {
          return <SntText value={cell.row.original.organisationName}></SntText>;
        },
        cellCopyData: (data) => {
          return [data.organisationName];
        },
        cellFilterValue: (data) => {
          return [data.organisationId];
        },
      },
      {
        key: "description",
        title: language.description_key,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "internalDescription",
        title: language.internal_description,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "handledByPartner",
        title: language.handled_by_partner,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
      {
        key: "closedAt",
        title: language.closed_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          var full = cell.row.original;
          return (
            <div>
              {permissionsMap["RMA_DECLARATION_MANAGE"] && (
                <SntActionButton
                  title={language.edit_key}
                  to={`/rma_declaration_manage?id=${full.id}&type=edit`}
                >
                  <SntPencilIcon />
                </SntActionButton>
              )}

              <SntDownloadButton
                title={language.download_key}
                linkDownload={rmaDeclarationClient.getDownloadLink(full.id)}
              />

              {permissionsMap["RMA_DECLARATION_MANAGE"] && (
                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      rmaDeclarationClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.resetDefault();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </div>
  );
};

export default RMADeclarationTableSaveView;
