const _maintenanceProgramsOptions = (loginInfo, orgId, isPartner, isSystem) => {
  let queryObjectType = "MAINTENANCE_PROGRAM";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "parameter", "status"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [
        "totalAsset",
        "numberOfOkAssets",
        "numberOfOverdueAssets",
        "numberOfReminderAssets",
        "numberOfUnInitializedAssets",
      ],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "parameter",
        "threshold",
        "startType",
        "announceDueWithin",
        "createdBy",
        "createdAt",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _maintenanceProgramsOptions;
