import { useRef } from "react";

import useSaveView from "@containers/SaveViewContainer/useSaveView";
import { useSelector } from "react-redux";
import SntDialog from "@/components/SntDialog/SntDialog";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import SntInputTextField from "@/cleanup/widgets/formiks/SntInputTextField";
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";

const EditSaveViewDialog = ({ dataSaveViewForm, onClose, isRename }) => {
  const language = useSelector((state) => state.language);

  const formikRef = useRef();
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(language.valid_required_key),
  });

  const {
    saveViewState,
    onAddSaveView,
    onEditSaveView,
    selectedId,
    handleUpdateSaveView,
  } = useSaveView();

  const onSave = (values) => {
    let params = {};
    if (isRename) {
      params = {
        title: values.title,
        id: dataSaveViewForm.id,
        pageName: dataSaveViewForm.pageName,
        favourite: dataSaveViewForm.favourite,
      };
    } else {
      params = {
        ...values,
        filter: saveViewState.filter,
        map: saveViewState.map,
        table: saveViewState.table,
        // filterConfig: saveViewState.filter
        //   ? JSON.stringify(saveViewState.filter)
        //   : "",
        // tableConfig: saveViewState.table
        //   ? JSON.stringify(saveViewState.table)
        //   : "",
        // mapConfig: saveViewState.map ? JSON.stringify(saveViewState.map) : "",
        switchMode: saveViewState.switchMode || "TABLE",
        pageName: saveViewState.pageName,
      };
    }

    handleUpdateSaveView(params, (item) => {
      if (isRename && selectedId !== item.id) {
        onEditSaveView(item);
      } else {
        onAddSaveView(item);
      }
      onClose();
    });
  };

  return (
    <>
      {dataSaveViewForm && (
        <SntDialog
          isShow={true}
          onClose={onClose}
          isShowCloseButton={true}
          title={isRename ? "Rename view" : "Save view"}
          onSave={() => formikRef.current.handleSubmit()}
          saveTxt={language.save_key}
        >
          {() => (
            <Formik
              initialValues={dataSaveViewForm}
              innerRef={formikRef}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={onSave}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    {/* {error && (
                    <FormFullScreenLayout
                      rightRender={() => (
                        <>
                          <Form.Control.Feedback
                            type="invalid"
                            className={`d-block`}
                          >
                            {error}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  )} */}
                    {!isRename && (
                      <SntInfoPanel>
                        {
                          "A saved view stores the applied filters, column selection and map display options (if applicable)."
                        }
                      </SntInfoPanel>
                    )}

                    <FormFullScreenLayout
                      label={language.title_key}
                      rightRender={() => (
                        <SntInputTextField
                          name="title"
                          placeholder={language.title_key}
                          required={true}
                        />
                      )}
                    />

                    {!isRename && (
                      <FormFullScreenLayout
                        rightRender={() => (
                          <div>
                            <SntCheckBox
                              checked={values.favourite}
                              onChange={(value) => {
                                setFieldValue("favourite", value);
                              }}
                              label={"Add as favourite"}
                            />
                          </div>
                        )}
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
        </SntDialog>
      )}
    </>
  );
};

export default EditSaveViewDialog;
