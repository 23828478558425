const _reportScheduleOptions = (loginInfo, orgId, isPartner) => {
  let { permissionsMap, features } = loginInfo;

  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "active",
        "autoDisabled",
        "scheduleName",
        "reportType",
        "scheduleFrequency",
        "nextOccurrence",
        "sendTo",
        "actions",
      ],
      searchKeys: ["scheduleName"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  if (features.visibility_filters && permissionsMap["VISIBILITY_FILTER_VIEW"]) {
    pageOptions.tableOptions.visibleCols = [
      "active",
      "autoDisabled",
      "scheduleName",
      "reportType",
      "scheduleFrequency",
      "nextOccurrence",
      "sendTo",
      "visibilityFilters",
      "actions",
    ];
  }

  return pageOptions;
};

export default _reportScheduleOptions;
