const _devAccountOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "apiKey",
        "numOfCallsThisMonth",
        "maxNbrOfCallsPerMonth",
        "ipPattern",
        "active",
        "systemAccount",
        "enableCertificate",
        "device",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _devAccountOptions;
