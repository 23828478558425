const _rmaDeclarationViewOptions = (loginInfo, orgId, isPartner, isSystem) => {
  let queryObjectType = "RMA_DECLARATION";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["zendeskId", "status", "description"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "zendeskId",
        "status",
        "deviceSerials",
        "description",
        "createdAt",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _rmaDeclarationViewOptions;
