/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";
import searchUIClient from "@/apis/searchUIClient";
import {
  getLinkWithSelectedOrgId,
  PAGES,
} from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import { useOrg } from "@/contexts/OrgContext";
import FilterUtils from "@/components/AdvancedFilters/FilterUtils";
import SystemTagListTable from "./SystemTagListTable";

const SystemTagList = ({ reload }) => {
  const { orgId, updateOrg } = useOrg();
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap, organisation } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    FilterUtils.addOrgInSidebarToFilter(params, orgId);
    params["selectedOrgId"] = orgId;

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (
      organisation.organisationType === "SYSTEM" &&
      permissionsMap["DEVICE_UPDATE"]
    ) {
      list.push([
        {
          id: "heroAction",
          title: language.new_tag_key,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: getLinkWithSelectedOrgId(
            `/system_tag_manage?type=add`,
            orgId || loginInfo.organisation.id
          ),
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (orgId == null) {
      console.log("orgId", orgId);
      updateOrg(loginInfo.organisation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <SaveViewPage
      pageName={PAGES.SYSTEM_TAG.pageName}
      reload={reload}
      title={language.system_tags}
      onRequestData={onRequestData}
      tableRender={() => {
        return <SystemTagListTable orgId={orgId} />;
      }}
      sections={sections}
      orgId={orgId}
    />
  );
};

export default SystemTagList;
