import { useSelector } from "react-redux";

import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";
import searchUIClient from "@/apis/searchUIClient";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import { useOrg } from "@/contexts/OrgContext";
import FilterUtils from "@/components/AdvancedFilters/FilterUtils";
import PrivateCellularTableSaveView from "./PrivateCellularTableSaveView";

const PrivateCellular = ({ reload }) => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);

  const onRequestData = (params, successCallback, errorCallback) => {
    FilterUtils.addOrgInSidebarToFilter(params, orgId);
    params["selectedOrgId"] = orgId;

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  return (
    <SaveViewPage
      pageName={PAGES.PRIVATE_CELLLAR.pageName}
      reload={reload}
      title={language.UserPermissionCategory_PRIVATE_CELLULAR_title_key}
      onRequestData={onRequestData}
      tableRender={() => {
        return <PrivateCellularTableSaveView orgId={orgId} />;
      }}
      orgId={orgId}
    />
  );
};

export default PrivateCellular;
