const _maintenanceProgramsStatisticsOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "MAINTENANCE_PROGRAM";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "parameter", "status"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "parameter",
        "totalAsset",
        "numberOfOkAssets",
        "numberOfOverdueAssets",
        "numberOfReminderAssets",
        "startType",
        "numberOfUnInitializedAssets",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _maintenanceProgramsStatisticsOptions;
