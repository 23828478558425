const _organisationDemoOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "platformPlanKey",
        "scenario",
        "language",
        "demoState",
        "buildPercentage",
        "createdAt",
        "expireAt",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _organisationDemoOptions;
