import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";

import { useSelector } from "react-redux";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import searchUIClient from "@/apis/searchUIClient";
import CellularSimTable from "./CellularSimTable";

const CellularSim = ({ reload }) => {
  const language = useSelector((state) => state.language);

  const onRequestData = (params, successCallback, errorCallback) => {
    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  return (
    <SaveViewPage
      pageName={PAGES.CELLULAR_SIM.pageName}
      reload={reload}
      onRequestData={onRequestData}
      title={language.UserPermission_MENU_ADMIN_CELLULAR_SIM_description_key}
      tableRender={() => {
        return <CellularSimTable />;
      }}
    />
  );
};

export default CellularSim;
