import { useEffect, useRef, useState } from "react";

const PageLayout = ({ topContent, children }) => {
  const layoutRef = useRef(null);
  const topRef = useRef(null);
  const [height, setHeight] = useState("100vh"); // fallback

  useEffect(() => {
    const updateHeight = () => {
      const topOutside = layoutRef.current?.getBoundingClientRect()?.top || 0;
      const topInside = topRef.current?.offsetHeight || 0;
      const available = window.innerHeight - topOutside - topInside;
      setHeight(`${available}px`);
    };

    // Update on window resize
    window.addEventListener("resize", updateHeight);

    // 🔥 Observe dynamic height changes inside topContent
    const resizeObserver = new ResizeObserver(() => updateHeight());
    if (topRef.current) {
      resizeObserver.observe(topRef.current);
    }

    updateHeight(); // initial call after mount

    return () => {
      window.removeEventListener("resize", updateHeight);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={layoutRef} className="w-100">
      {/* Top dynamic content inside pink box */}
      <div ref={topRef}>{topContent}</div>

      {/* Main content that fills remaining height */}
      <div className="d-flex flex-column" style={{ height }}>
        <div className="flex-grow-1 position-relative">
          {children && children(height)}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
