const _cellularSimOptions = (loginInfo, orgId, isPartner, isSystem) => {
  let queryObjectType = "CELLULAR_SIM";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "autoDetectedImei",
        "cellularAccount",
        "deviceIdentifier",
      ],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "iccid",
        "imsi",
        "device",
        "cellularState",
        "cellularAccount",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _cellularSimOptions;
