import SntBrowserTabTitle from "../../components/SntBrowserTabTitle/SntBrowserTabTitle";
import BackHistory from "../BackHistory/BackHistory";
import Cookies from "js-cookie";

import styled from "styled-components";

const PageHeaderStyle = styled.h1`
  font-size: 24px;
  margin: 0;
  padding: 0;
  background: #fff;
  color: #212851;
  font-weight: 300;
  width: auto;
  box-shadow: 0 0px 0 #fff;
  border-bottom-color: transparent;
  line-height: 1.3;

  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  white-space: nowrap;
`;

const PageHeader = ({
  title,
  extraTitle,
  tabTitle,
  renderRightHeader,
  renderHeaderIcon,
  isShowBackHistory = true,
}) => {
  const sensolusFullScreen = Cookies.get("sensolus-full-screen");

  if (sensolusFullScreen) return <></>;
  return (
    <>
      <SntBrowserTabTitle title={tabTitle || title} />

      <div
        className="d-flex justify-content-between align-items-center heading"
        style={{ margin: "12.5px 0 12.5px 0" }}
      >
        <div style={{ maxWidth: "60%" }}>
          <div className="d-flex align-items-baseline" title={tabTitle}>
            {isShowBackHistory && <BackHistory />}
            <PageHeaderStyle>{title}</PageHeaderStyle>
            {extraTitle && <> {extraTitle}</>}
            {renderHeaderIcon && renderHeaderIcon()}
          </div>
        </div>
        <div style={{ maxWidth: "40%", flexGrow: 1 }}>
          <div className="d-flex justify-content-end align-items-center">
            {renderRightHeader && renderRightHeader()}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
