const _utilizationDashboardOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "creationDate",
        "ownedBy",
        "status",
        "startDate",
        "endDate",
      ],
      searchKeys: ["name"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _utilizationDashboardOptions;
