import useSaveView from "@containers/SaveViewContainer/useSaveView";
import { useSelector } from "react-redux";
import SntSaveViewIcon from "@/components/Icons/SntSaveViewIcon";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import SntLink from "@/components/ReactBootstrap/SntLink";
import SntSearch from "@/components/ReactBootstrap/SntSearch";
import { useEffect, useState } from "react";
import SntSmallMenu from "@/components/Icons/SntSmallMenu";
import EditSaveViewDialog from "./EditSaveViewDialog";
import saveViewClient from "@/apis/saveViewClient";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";

const ListSaveView = ({ autoSaveViewName, onResetToDefault }) => {
  const language = useSelector((state) => state.language);
  const [dataSaveViewForm, setDataSaveViewForm] = useState(null);

  const {
    saveViewItems,
    selectedId,
    onSelectSaveView,
    onEditSaveView,
    onDeleteSaveView,
    handleResetSaveViewToDefault,
    handleResetToAutosavedView,
    handleUpdateSaveView,
  } = useSaveView();

  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState([]);

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    title: "Delete the view",
    onDelete: (itemSelected) => {
      saveViewClient
        .delete(itemSelected.id)
        .then((data) => {
          onDeleteSaveView(itemSelected);
          handleClose();
        })
        .catch((error) => {
          let message = language.request_error_key;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  useEffect(() => {
    if (search === "") {
      setFilterItems([...saveViewItems]);
    } else {
      const lowerCaseSearchText = search.toLowerCase();
      setFilterItems(
        saveViewItems.filter((item) =>
          item.title.toLowerCase().includes(lowerCaseSearchText)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, saveViewItems]);

  const onClose = () => {
    setDataSaveViewForm(null);
  };

  const onSwitchFavourite = (item) => {
    let params = {
      title: item.title,
      id: item.id,
      pageName: item.pageName,
      favourite: !item.favourite,
    };

    handleUpdateSaveView(params, (data) => {
      onEditSaveView(data);
      onClose();
    });
  };

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      <>
        <div>Are you sure you want to delete this {item.title} view?</div>
        <div>You can not undo this</div>
      </>
    );
  };

  const renderListItem = (list, isOthers) => {
    return list.map((item) => {
      return (
        <Dropdown.Item
          key={item.id}
          bsPrefix="dropdown-item"
          className={`${item.id === selectedId ? "active" : ""}`}
          onClick={() => {
            onSelectSaveView(item.id);
            onResetToDefault && onResetToDefault();
          }}
        >
          <div className="d-flex">
            <div className="flex-grow-1">{item.title}</div>

            <div
              style={{ width: 20 }}
              className="align-items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Dropdown
                className={`hide-arrow`}
                size="md"
                style={{ zIndex: 1007 }}
                as={ButtonGroup}
              >
                <Dropdown.Toggle as={SntLink} style={{ zIndex: 1008 }}>
                  <SntSmallMenu />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    zIndex: 1009,
                    marginLeft: "25px",
                    marginTop: "-25px",
                  }}
                >
                  {item.id !== selectedId && (
                    <Dropdown.Item
                      bsPrefix="dropdown-item"
                      onClick={(e) => {
                        onDeleteClicked(e, item);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    bsPrefix="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSwitchFavourite(item);
                    }}
                  >
                    {isOthers ? "Favourite" : "Unfavourite"}
                  </Dropdown.Item>

                  <Dropdown.Item
                    bsPrefix="dropdown-item"
                    onClick={(e) => {
                      setDataSaveViewForm(item);
                    }}
                  >
                    Rename
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Dropdown.Item>
      );
    });
  };

  return (
    <>
      {dataSaveViewForm && (
        <EditSaveViewDialog
          dataSaveViewForm={dataSaveViewForm}
          isRename={true}
          onClose={onClose}
        />
      )}

      <DeleteDialog />

      <Dropdown
        className={`hide-arrow ms-3 mt-1`}
        size="md"
        style={{ zIndex: 1002 }}
        as={ButtonGroup}
      >
        <Dropdown.Toggle as={SntLink}>
          <SntSaveViewIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            bsPrefix="dropdown-item"
            onClick={(e) => {
              handleResetToAutosavedView();
              onResetToDefault && onResetToDefault();
            }}
          >
            {autoSaveViewName} (AutoSaved view)
          </Dropdown.Item>

          <Dropdown.Divider />

          <div
            style={{
              padding:
                "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)",
            }}
          >
            <SntSearch
              value={search}
              onChange={(text) => {
                setSearch(text);
              }}
              placeholder={"Search for views"}
            />
          </div>

          <Dropdown.Divider />

          <div
            style={{
              padding:
                "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)",
            }}
            className="fw-bold"
          >
            Favourite
          </div>

          {filterItems.filter((item) => item.favourite).length ? (
            renderListItem(
              filterItems.filter((item) => item.favourite),
              true
            )
          ) : (
            <NoView title={"No favourites yet"} />
          )}

          <Dropdown.Divider />

          <div
            style={{
              padding:
                "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)",
            }}
            className="fw-bold"
          >
            Others
          </div>

          {filterItems.filter((item) => !item.favourite).length ? (
            renderListItem(
              filterItems.filter((item) => !item.favourite),
              true
            )
          ) : (
            <NoView title={"No saved views yet"} />
          )}

          <Dropdown.Divider />

          <div
            style={{
              padding:
                "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)",
            }}
          >
            <SntLink
              onClick={() => {
                handleResetSaveViewToDefault();
                onResetToDefault && onResetToDefault();
              }}
            >
              Reset current view to default
            </SntLink>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const NoView = ({ title }) => {
  return (
    <div
      style={{
        padding:
          "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        verticalAlign: "middle",
      }}
      className="fw-normal fst-italic text-body"
    >
      {title}
    </div>
  );
};

export default ListSaveView;
