const _productAutoUpgradeOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "sourceProductKey",
        "targetProductKey",
        "firmwareRegexp",
        "actions",
      ],
      searchKeys: ["sourceProductKey", "targetProductKey", "firmwareRegexp"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _productAutoUpgradeOptions;
