import { apiClient } from "./apiClient";

export default {
  update(data) {
    return apiClient.post(`/rest/save_view`, data);
  },

  getAll(pageName) {
    return apiClient.get(`/rest/save_view/${pageName}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/save_view/${id}`);
  },
};
