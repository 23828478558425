const _notificationOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "NOTIFICATION";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "createdBy", "status", "visibility"],
      restrictedFilters: [
        "accessibleByOrganizationDtos",
        "accessibleByPartnersDtos",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: ["name", "createdBy", "status"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _notificationOptions;
