import { useMemo } from "react";

import { useSelector } from "react-redux";

import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";
import searchUIClient from "@/apis/searchUIClient";
import {
  getLinkWithSelectedOrgId,
  PAGES,
} from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import { useOrg } from "@/contexts/OrgContext";
import FilterUtils from "@/components/AdvancedFilters/FilterUtils";
import MetarWeatherLocationTableSaveView from "./MetarWeatherLocationTableSaveView";

const MetarWeatherLocation = ({ reload }) => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user);
  const { features, permissionsMap } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    FilterUtils.addOrgInSidebarToFilter(params, orgId);

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (
      features["metar_weather_location"] &&
      permissionsMap["METAR_WEATHER_LOCATION_MANAGE"]
    ) {
      list.push([
        {
          id: "heroAction",
          title: language.add_metar_weather_service,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: getLinkWithSelectedOrgId(
            `/metar_weather_location_manage?type=create`,
            orgId || loginInfo.organisation.id
          ),
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <SaveViewPage
      pageName={PAGES.METAR_WEATHER_LOCATION.pageName}
      reload={reload}
      title={language.metar_weather_service_locations}
      onRequestData={onRequestData}
      tableRender={() => {
        return <MetarWeatherLocationTableSaveView />;
      }}
      sections={sections}
      orgId={orgId}
    />
  );
};

export default MetarWeatherLocation;
