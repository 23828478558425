const _zendeskSupportTicketsOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "ZENDESK_SUPPORT_TICKET";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["subject", "status", "createdAt"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organizations"],
      columnVisibleInOrder: [],
      visibleCols: [
        "ticketId",
        "subject",
        "requesterEmail",
        "status",
        "createdAt",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _zendeskSupportTicketsOptions;
