import { useEffect, useMemo, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

import privateCellularClient from "@/apis/privateCellularClient";
import $ from "jquery";
import { Button } from "react-bootstrap";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntPanel from "@/components/Panel/SntPanel";
import SntLink from "@/components/ReactBootstrap/SntLink";
import SntDialog from "@/components/SntDialog/SntDialog";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import SntWarning from "@/components/SntInfo/SntWarning";
import StringUtils from "@/utils/StringUtils";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntText from "@/components/ReactBootstrap/SntText";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const PrivateCellularTableSaveView = ({ orgId }) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap, features, organisation } = loginInfo;

  const table = useRef();

  const fileRef = useRef();
  const [uploadError, setUploadError] = useState("");

  const [dialogMessage, setDialogMessage] = useState(language.error_key);
  const [isShowDialogMessage, setShowDialogMessage] = useState(false);

  const onUploadClicked = () => {
    $(fileRef.current).click();
  };

  useEffect(() => {
    $(fileRef.current).change(function () {
      let render = new FileReader(),
        input = this;

      render.onload = function (e) {
        let form = $(fileRef.current).closest("form");
        let dataForm = new FormData(form[0]);

        privateCellularClient
          .uploadPrivateCellularFile(
            orgId || loginInfo.organisation.id,
            dataForm
          )
          .then(({ data }) => {
            setUploadError(null);
            setDialogMessage(
              StringUtils.replaceStr(language.v43_successful_key, data)
            );
            setShowDialogMessage(true);
            table.current && table.current.refresh();
          })
          .catch(({ response }) => {
            setDialogMessage(
              response?.data?.message || language.update_fail_keyupdate_fail_key
            );
            setShowDialogMessage(true);
            setUploadError(response.data.message);
          });
      };
      render.readAsDataURL(input.files[0]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allColumns = useMemo(
    () => [
      {
        key: "cellId",
        title: language.cell_id,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "cellularInfo",
        title: language.cellular_tower_info,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          var info =
            "mcc: " +
            (full.mcc || language.unknown_key) +
            ", mnc: " +
            (full.net || language.unknown_key) +
            ", cell: " +
            (full.cellId || language.unknown_key);
          return <div>{info}</div>;
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.mcc) {
            text += " - " + data.mcc;
          }
          if (data.net) {
            text += " - " + data.net;
          }
          if (data.cellId) {
            text += " - " + data.cellId;
          }
          return [text];
        },
      },
      {
        key: "actualLocation",
        title: language.v41_locations_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.actualLat && full.actualLng ? (
            <>
              <div className="d-flex">
                latitude: {full.actualLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.actualLat +
                    "," +
                    full.actualLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.actualLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const canEdit = () => {
    return (
      features["only_private_cellular_resolution"] &&
      permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
      (organisation.organisationType !== "PARTNER" ||
        features["partner_org_manage"])
    );
  };

  return (
    <>
      <SntDialog
        isShow={isShowDialogMessage}
        onSave={() => {
          setShowDialogMessage(false);
        }}
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.notifications_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>

      <SntPanel title={language.configure_private_cellular}>
        <SntInfoPanel>
          {ReactHtmlParser(language.private_cellular_header_key)}
          <ul>
            <li>{language.mac_address_step_1_key}</li>
            <li>{language.private_cellular_step_2_key}</li>
            <li>{language.mac_address_step_3_key}</li>
          </ul>
        </SntInfoPanel>

        {uploadError && (
          <SntWarning>
            <IcomoonIcon
              icon="warning"
              size="18"
              className="me-2"
            ></IcomoonIcon>
            {uploadError}
          </SntWarning>
        )}

        <form>
          {features["only_private_cellular_resolution"] &&
            permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
            (organisation.organisationType !== "PARTNER" ||
              features["partner_org_manage"]) && (
              <div className="d-flex">
                <div className="align-self-center">
                  <Button
                    variant="sensolus-greylight"
                    type="button"
                    className="me-2"
                    disabled={!canEdit()}
                    onClick={onUploadClicked}
                  >
                    <span className="fw-bold">{language.upload_excel_key}</span>
                  </Button>
                  <input
                    name="file"
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    disabled={!canEdit()}
                    ref={fileRef}
                  />
                </div>
                <div className="align-self-center me-1">
                  <SntLink
                    onClick={() => privateCellularClient.downloadTemplateFile()}
                  >
                    {language.download_example_excel_key}
                  </SntLink>
                </div>
                <span className="align-self-center"> | </span>
                <div className="align-self-center ms-1">
                  <SntLink
                    onClick={() => privateCellularClient.exportAsExcel(orgId)}
                  >
                    {language.export_as_xls_key}
                  </SntLink>
                </div>
              </div>
            )}
        </form>
      </SntPanel>

      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={[]}
      />
    </>
  );
};

export default PrivateCellularTableSaveView;
