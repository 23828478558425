import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";

import { useSelector } from "react-redux";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import searchUIClient from "@/apis/searchUIClient";
import { useMemo } from "react";
import RMADeclarationTableSaveView from "./RMADeclarationTableSaveView";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";

const RMADeclaration = ({ reload }) => {
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (permissionsMap["RMA_DECLARATION_MANAGE"]) {
      list.push([
        {
          id: "heroAction",
          title: language.add_key,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: `/rma_declaration_manage?type=add`,
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SaveViewPage
      pageName={PAGES.RMA_DECLARATION.pageName}
      reload={reload}
      onRequestData={onRequestData}
      title={language.device_return}
      tableRender={() => {
        return <RMADeclarationTableSaveView />;
      }}
      sections={sections}
    />
  );
};

export default RMADeclaration;
