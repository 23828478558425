import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";

import { useSelector } from "react-redux";
import { PAGES } from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import searchUIClient from "@/apis/searchUIClient";
import { useMemo } from "react";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import CellularAccountTableSaveView from "./CellularAccountTableSaveView";

const CellularSim = ({ reload }) => {
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (permissionsMap["CELLULAR_ACCOUNT_MANAGE"]) {
      list.push([
        {
          id: "heroAction",
          title: language.add_cellular_account_key,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: "/cellular_account_manage?type=create",
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SaveViewPage
      pageName={PAGES.CELLULAR_ACCOUNT.pageName}
      reload={reload}
      onRequestData={onRequestData}
      title={
        language.UserPermission_MENU_ADMIN_CELLULAR_ACCOUNT_description_key
      }
      tableRender={() => {
        return <CellularAccountTableSaveView />;
      }}
      sections={sections}
    />
  );
};

export default CellularSim;
