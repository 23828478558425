import DateModeView from "@widgets/dates/DateModeView";
import moment from "moment";

const _manufacturingOptions = (loginInfo, orgId, isPartner) => {
  const { weekStart } = loginInfo;

  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
      rangeDates: {
        viewMode: DateModeView.week,
        start: weekStart
          ? moment().startOf("isoWeek")
          : moment().startOf("isoWeek").subtract(1, "days"),
        end: weekStart
          ? moment().endOf("isoWeek")
          : moment().endOf("isoWeek").subtract(1, "days"),
        overrideDisplays: { year: false },
      },
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "serial",
        "lifecycleState",
        "boardManufacturingID",
        "currentManufacturingState",
        "poItemNumber",
        "techProductDescriptorKey",
        "latestStateChange",
        "keyvault",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _manufacturingOptions;
