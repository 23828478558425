import { useEffect, useMemo } from "react";

import SaveViewPage from "@/cleanup/containers/SaveViewContainer/SaveViewPage";

import { useOrg } from "@/contexts/OrgContext";
import { useSelector } from "react-redux";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import {
  getLinkWithSelectedOrgId,
  PAGES,
} from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import searchUIClient from "@/apis/searchUIClient";
import PartnerTagListTableSaveView from "./PartnerTagListTableSaveView";

const PartnerTagList = ({ reload, isSystem }) => {
  const { orgId, updateOrg } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap, organisation } = loginInfo;

  const onRequestData = (params, successCallback, errorCallback) => {
    if (orgId) {
      params["selectedOrgId"] = orgId;
    }

    searchUIClient.search(params).then(
      ({ data }) => {
        successCallback && successCallback(data);
      },
      (err) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  const sections = useMemo(() => {
    let list = [];

    if (
      (organisation.organisationType === "SYSTEM" ||
        organisation.organisationType === "PARTNER") &&
      permissionsMap["DEVICE_UPDATE"]
    ) {
      list.push([
        {
          id: "heroAction",
          title: language.new_tag_key,
          icon: <AwesomeIcon icon="plus"></AwesomeIcon>,
          link: getLinkWithSelectedOrgId(`/partner_tag_manage?type=add`, orgId),
          fullLinkInNewTab: null,
          customActiveClass: null,
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgId == null) {
      updateOrg(loginInfo.organisation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <SaveViewPage
      pageName={PAGES.PARTNER_TAG.pageName}
      reload={reload}
      onRequestData={onRequestData}
      orgId={orgId}
      title={language.partner_tags}
      tableRender={() => {
        return <PartnerTagListTableSaveView orgId={orgId} />;
      }}
      sections={sections}
      isSystem={isSystem}
    />
  );
};

export default PartnerTagList;
