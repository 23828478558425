import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button, Dropdown, SplitButton } from "react-bootstrap";

import useSaveView from "@containers/SaveViewContainer/useSaveView";
import EditSaveViewDialog from "./EditSaveViewDialog";

const CustomToggle = styled(SplitButton)`
  .dropdown-toggle {
    border-left: 1px solid white !important;
  }
`;

const ButtonsSaveView = ({ defaultTitle }) => {
  const language = useSelector((state) => state.language);
  const [dataSaveViewForm, setDataSaveViewForm] = useState(null);

  const {
    selectedId,
    saveViewState,
    isChanged,
    handleUpdateSaveView,
    onAddSaveView,
  } = useSaveView();

  const onClose = () => {
    setDataSaveViewForm(null);
  };

  return (
    <>
      {dataSaveViewForm && (
        <EditSaveViewDialog
          dataSaveViewForm={dataSaveViewForm}
          onClose={onClose}
        />
      )}

      {isChanged ? (
        <>
          {selectedId ? (
            <CustomToggle
              variant={"primary"}
              id={`dropdown-button-drop-down`}
              drop={"down"}
              title={language.save_key}
              onClick={() => {
                handleUpdateSaveView(
                  {
                    ...saveViewState,
                    title: saveViewState.title || defaultTitle,
                  },
                  (item) => {
                    onAddSaveView(item);
                  }
                );
              }}
              className="ms-3"
            >
              <Dropdown.Item
                onClick={() => {
                  setDataSaveViewForm({
                    title: "",
                    favourite: saveViewState.favourite || false,
                  });
                }}
              >
                {language.save_as_new_view_key}
              </Dropdown.Item>
            </CustomToggle>
          ) : (
            <Button
              variant="sensolus-greylight"
              className="ms-3"
              onClick={() => {
                setDataSaveViewForm({
                  title: "",
                  favourite: false,
                });
              }}
            >
              {language.save_as_new_view_key}
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ButtonsSaveView;
