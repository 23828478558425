const _infrastructureOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "SIGFOX_DEVICE";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "bleBeaconIdentifier",
        "trackerStatus",
        "productKeyNonTech",
        "lastSeenAlive",
      ],
      restrictedFilters: [
        "assetName",
        "assetTagsName",
        "locationByBound",
        "deviceCategory",
        "lastNetworkOverridePrecise",
        "alertRuleName",
        "decoratorColor",
      ],
      categories: ["INFRA"],
      deviceCategorySuggestion: "INFRA",
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: ["INFRA"],
      preventCols: ["location"],
      visibleCols: [
        "name",
        "serial",
        "mac",
        "address",
        "creationDate",
        "lastSeen",
        "status",
        "nonTechProductDescriptorKey",
        "infrastructureGeobeaconSerial",
        "advertisementName",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      columnVisibleInOrder: [],
      orderData: null,
      groups: null,
    },
    mapOptions: {
      exportConfig: {
        columnVisibleInOrder: [],
        orderData: null,
      },
    },
  };

  if (orgId) {
    pageOptions.filterOptions.restrictedFilters.push("organizations");
  }

  let { features, organisation } = loginInfo;
  if (
    features.whitelabel_identifiers &&
    organisation.organisationType === "NORMAL"
  ) {
    pageOptions.tableOptions.preventCols.push("serial");
  }

  return pageOptions;
};

export default _infrastructureOptions;
