const _geolayersOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      visibleCols: ["name", "type"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      columnVisibleInOrder: [],
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _geolayersOptions;
