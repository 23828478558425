import { DEVICE_SUMMARY_KEY } from "@containers/SaveViewContainer/pageUtils/PageUtils.js";

const _assetListOptions = (loginInfo, orgId, isPartner) => {
  const { features, organisation } = loginInfo;
  let queryObjectType = "SIGFOX_DEVICE";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "deviceIdentifier",
        "trackerStatus",
        "organizations",
      ],
      restrictedFilters: [
        "assetName",
        "assetTagsName",
        // "locationByBound",
        "deviceCategory",
        "lastNetworkOverridePrecise",
        "alertRuleName",
        "decoratorColor",
      ],
      categories: ["DEVICE"],
      deviceCategorySuggestion: "TRACKER",
      includeDynamicFilter: true,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: ["DEVICE"],
      preventCols: ["location"],
      visibleCols: [
        "name",
        "serial",
        "thirdPartyId",
        "deviceTag",
        "status",
        "lastGeozone",
        "lastActivity",
      ],
      includeDynamicColumn: true,
      pageSize: 25,
      pageIndex: 0,
      columnVisibleInOrder: [],
      orderData: null,
      groups: null,
    },
    mapOptions: {
      queryObjectType: queryObjectType,
      categories: ["DEVICE"],
      preventCols: ["name", "location", "decoratorColor"],
      visibleCols: [DEVICE_SUMMARY_KEY],
      includeDynamicColumn: true,
      showCellLabels: false,
    },
  };

  let visibleCols = pageOptions.tableOptions.visibleCols;
  let preventCols = pageOptions.tableOptions.preventCols;
  pageOptions.tableOptions.visibleCols = features.whitelabel_identifiers
    ? visibleCols.map((key) => (key === "serial" ? "secondarySerial" : key))
    : visibleCols;

  pageOptions.tableOptions.preventCols =
    features.whitelabel_identifiers &&
    organisation.organisationType === "NORMAL"
      ? [...preventCols, "serial"]
      : preventCols;

  return pageOptions;
};

export default _assetListOptions;
