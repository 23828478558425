import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import metarWeatherLocationClient from "@apis/metarWeatherLocationClient.js";
import useDeleteDialog from "@widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntLink from "@/components/ReactBootstrap/SntLink";
import { SensolusDatetimeReact } from "@/constants/Config";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const MetarWeatherLocationTableSaveView = () => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;
  const table = useRef();

  const allColumns = useMemo(() => {
    return [
      {
        key: "name",
        title: language.name_key,
        Cell: ({ cell, value }) => {
          return (
            <div>
              <SntLink
                to={`/metar_weather_location_manage?type=view&id=${cell.row.original.id}`}
              >
                {value}
              </SntLink>
            </div>
          );
        },
      },
      {
        key: "address",
        title: language.v41_address_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "latitude",
        title: language.latitude_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "longitude",
        title: language.longitude_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "radius",
        title: language.radius_new_key,
        Cell: ({ cell, value }) => {
          return value + " " + language.meters_key;
        },
      },
      {
        key: "createdBy",
        title: language.created_by_key,
        Cell: ({ cell, value }) => {
          return cell.row.original.userId ? (
            <div>
              <SntLink
                to={`/users_admin_update?type=view&id=${cell.row.original.userId}`}
              >
                {cell.row.original.userFullName}
              </SntLink>
            </div>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          return [data.userFullName];
        },
        cellFilterValue: (data) => {
          return [data.userFullName];
        },
      },
      {
        key: "createdAt",
        title: language.created_at_key,
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(() => {
    if (permissionsMap["METAR_WEATHER_LOCATION_MANAGE"]) {
      return [
        {
          key: "actions",
          title: language.actions_key,
          immovable: true,
          disableSortBy: true,
          canResize: false,
          Cell: ({ cell }) => {
            var full = cell.row.original;
            return (
              <div>
                <SntActionButton
                  title={language.edit_key}
                  to={`/metar_weather_location_manage?type=edit&id=${cell.row.original.id}`}
                >
                  <SntPencilIcon />
                </SntActionButton>

                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              </div>
            );
          },
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsMap]);

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      metarWeatherLocationClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </div>
  );
};

export default MetarWeatherLocationTableSaveView;
