const _roleOptions = (loginInfo, orgId, isPartner, isSystem) => {
  let queryObjectType = "ROLE_MANAGER";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "organizations"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "visibleForOrgType",
        "lastUserPermissions",
        "systemRole",
        "userCount",
        "organizations",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  if (
    isSystem &&
    loginInfo &&
    loginInfo.organisation &&
    (loginInfo.organisation.organisationType === "SYSTEM" ||
      loginInfo.organisation.organisationType === "PARTNER")
  ) {
    pageOptions.tableOptions.preventCols = ["organizations"];
    pageOptions.filterOptions.restrictedFilters = ["organizations"];
  }

  return pageOptions;
};

export default _roleOptions;
