const _webhooksOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "protocol",
        "httpMethod",
        "url_key",
        "endPoint",
        "topic",
        "aggregatedTypes",
        "enable",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _webhooksOptions;
