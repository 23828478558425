const _geozoneTagOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "GEOZONE_TAG";
  return {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "organisation"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organisationName"],
      columnVisibleInOrder: [],
      visibleCols: ["name", "numGeozones"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
};

export default _geozoneTagOptions;
