const _dashboardOptions = (loginInfo, orgId, isPartner) => {
  let { permissionsMap } = loginInfo;
  let queryObjectType = "BI";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "organizations"],
      restrictedFilters: [
        "accessibleByOrganizationDtos",
        "accessibleByPartnersDtos",
        "user",
        "description",
        "lastUpdateAt",
        "createdAt",
        "availabilityType",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["createdBy"],
      columnVisibleInOrder: [],
      visibleCols: ["name", "condition", "action", "active"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  if (!permissionsMap["DASHBOARD_MANAGE"]) {
    pageOptions.tableOptions.preventCols.push("active");
  }

  if (!isPartner) {
    pageOptions.filterOptions.restrictedFilters.push("organizations");
  }

  return pageOptions;
};

export default _dashboardOptions;
