import { useMemo, useRef } from "react";

import SntLink from "@/components/ReactBootstrap/SntLink.js";
import { SensolusDatetimeReact } from "@/constants/Config.js";
import CheckView from "@widgets/views/CheckView.js";
import { DeviceHomeRoute } from "@/cleanup/utils/routesUtil/routesUtil";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const CellularSimTable = () => {
  const table = useRef();

  const allColumns = useMemo(() => {
    return [
      {
        key: "iccid",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "imsi",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "autoDetectedImei",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "enabledImeiLock",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "enforceImeiLock",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "imeiMatching",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "cellularState",
        Cell: ({ cell, value }) => {
          const cellularState = value;
          return cellularState ? cellularState : "";
        },
        cellCopyData: (data) => {
          return [data.cellularState || ""];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularState || ""}`];
        },
      },
      {
        key: "customerServiceProfile",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "ragStatus",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "rogueUsageAlertPresent",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "highUsageAlertPresent",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceVolume",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceTotalVolume",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceExpiryDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "dtSubscriptionPackageName",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "dtSubscriptionPackageDescription",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "dtSimSpecificationId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "epsilonModel",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "epsilonFirstActivationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonContractExpirationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonContractDeactivationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonGidName",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyModelId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyModelDescription",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyMemorySize",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifySimId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyProductionDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "deutschetelekomConsumption",
        Cell: ({ cell, value }) => {
          return value !== undefined
            ? value + " " + cell.row.original.deutschetelekomUnit
            : "";
        },
      },
      {
        key: "device",
        Cell: ({ cell, value }) => {
          return (
            <SntLink
              to={DeviceHomeRoute.getUpdatePage({
                id: cell.row.original.deviceId,
              })}
            >
              {cell.row.original.deviceName}
            </SntLink>
          );
        },
        cellCopyData: (data) => {
          return [data.deviceName];
        },
        cellFilterValue: (data) => {
          return [data.deviceName];
        },
      },
      {
        key: "cellularAccount",
        Cell: ({ cell, value }) => {
          return (
            <SntLink
              to={`/cellular_account_manage?type=view&id=${cell.row.original.cellularAccountId}`}
            >
              {cell.row.original.cellularAccountName}
            </SntLink>
          );
        },
        cellCopyData: (data) => {
          return [data.cellularAccountName];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularAccountId || ""}`];
        },
      },
      {
        key: "insertedDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={[]}
      />
    </div>
  );
};

export default CellularSimTable;
