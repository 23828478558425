const _userOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "USER";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "email", "lastLogin", "organisation"],
      restrictedFilters: [
        "organizations",
        "visibilityFilterDtos",
        "organisationFilterDtos",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organisation_id"],
      columnVisibleInOrder: [],
      visibleCols: [
        "userName",
        "fullName",
        "email",
        "role",
        "status",
        "emailStatus",
        "lastLogin",
        "lastMonthLogin",
        "lastweekLogin",
        "lastImpersonate",
        "expirationDate",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
  return pageOptions;
};

export default _userOptions;
