import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import SntViewIcon from "@/components/Icons/SntViewIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntDownloadButton from "@/components/ReactBootstrap/SntDownloadButton";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

import RMAStatusLabel from "./RMAStatusLabel";

const RMADeclarationViewTableSaveView = ({ orgId }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();

  const allColumns = useMemo(
    () => [
      {
        key: "zendeskId",
        title: language.zendesk_support_id,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return (
            <a
              href={cell.row.original.linkToZendesk}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
      {
        key: "status",
        title: language.status_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return <RMAStatusLabel value={value} other="" />;
        },
      },
      {
        key: "deviceSerials",
        title: language.number_of_trackers_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value?.length || "";
        },
        cellCopyData: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
        cellFilterValue: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
      },
      {
        key: "description",
        title: language.description_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "internalDescription",
        title: language.internal_description,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "handledByPartner",
        title: language.handled_by_partner,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
      {
        key: "closedAt",
        title: language.closed_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          let full = cell.row.original;
          return (
            <div>
              <SntActionButton
                title={language.view_key}
                to={`/rma_declaration_view_details?id=${full.id}`}
              >
                <SntViewIcon />
              </SntActionButton>
              <SntDownloadButton
                title={language.download_key}
                linkDownload={rmaDeclarationClient.getDownloadLink(full.id)}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </div>
  );
};

export default RMADeclarationViewTableSaveView;
