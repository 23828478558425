import { useEffect, useState } from "react";

import useSaveView from "./useSaveView";
import { ActionEnum } from "@/components/SntTableViewCard/ActionEnum";
import Filters from "./Filters";
import { useSelector } from "react-redux";

const FiltersSaveView = ({ orgId }) => {
  const loginInfo = useSelector((state) => state.user);
  const { features } = loginInfo;

  const {
    saveViewState,
    filterOptions,
    updateFilter,
    setFilterMap,
    forceFilterAction,
    setForceFilterAction,
    setForceTableAction,
    getNewCols,
  } = useSaveView();

  const cloneSaveViewState = (state) => {
    let _clone = JSON.stringify(state);
    return JSON.parse(_clone);
  };

  const [query, setQuery] = useState(null);

  const onLoadFilter = (data) => {
    setFilterMap(data);
  };

  const onFilterChange = (filterData, action) => {
    if (action.type === ActionEnum.CHANGE_MORE_FILTER) {
      let columnKeys = getNewCols(filterData);
      setForceTableAction({ columnKeys, ACTION_TYPE: "CHANGE_MORE_FILTER" });
    }
    updateFilter(filterData, true);
  };

  useEffect(() => {
    setQuery(cloneSaveViewState(saveViewState.filter.query));
  }, [saveViewState.filter]);

  return (
    <>
      {query && (
        <Filters
          dataOptions={filterOptions}
          orgId={orgId}
          query={query}
          onChange={onFilterChange}
          onLoadFilter={onLoadFilter}
          forceFilterAction={forceFilterAction}
          setForceFilterAction={setForceFilterAction}
          isDisableAddFilterBtn={
            filterOptions.isDisableAddFilterBtn
              ? true
              : !(features["alert_search"] || features["search_advanced"])
          }
        />
      )}
    </>
  );
};

export default FiltersSaveView;
