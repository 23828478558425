import _assetListOptions from "./_assetListOptions";
import _automationOptions from "./_automationOptions";
import _dashboardOptions from "./_dashboardOptions";
import _deviceTagOptions from "./_deviceTagOptions";
import _notificationOptions from "./_notificationOptions";
import _organisationFiltersOptions from "./_organisationFiltersOptions";
import _productOptions from "./_productOptions";
import _trackerAdminOptions from "./_trackerAdminOptions";
import _userOptions from "./_userOptions";
import _visibilityFiltersOptions from "./_visibilityFiltersOptions";
import _activeAlertOptions from "./_activeAlertOptions";
import _zendeskOrderTicketsOptions from "./_zendeskOrderTicketsOptions";
import _zendeskSupportTicketsOptions from "./_zendeskSupportTicketsOptions";
import _zendeskTicketsOptions from "./_zendeskTicketsOptions";
import _alertRuleOptions from "./_alertRuleOptions";
import _auditLogOptions from "./_auditLogOptions";
import _externalBLESensorOptions from "./_externalBLESensorOptions";
import _systemTagOptions from "./_systemTagOptions";
import _geozoneTagOptions from "./_geozoneTagOptions";
import _eolDeviceOptions from "./_eolDeviceOptions";
import _metarWeatherLocationOptions from "./_metarWeatherLocationOptions";
import _privateCellularOptions from "./_privateCellularOptions";
import _deviceUsageProfileOptions from "./_deviceUsageProfileOptions";
import _partnerOptions from "./_partnerOptions";
import _organisationOptions from "./_organisationOptions";
import _organisationDemoOptions from "./_organisationDemoOptions";
import _geolayerGeneratorOptions from "./_geolayerGeneratorOptions";
import _reportOptions from "./_reportOptions";
import _reportScheduleOptions from "./_reportScheduleOptions";
import _historicalAlertOptions from "./_historicalAlertOptions";
import _geozoneVisitOptions from "./_geozoneVisitOptions";
import _maintenanceProgramsStatisticsOptions from "./_maintenanceProgramsStatisticsOptions";
import _maintenanceUpcomingOptions from "./_maintenanceUpcomingOptions";
import _maintenanceDoneOptions from "./_maintenanceDoneOptions";
import _webhooksOptions from "./_webhooksOptions";
import _devAccountOptions from "./_devAccountOptions";
import _apiAccessOptions from "./_apiAccessOptions";
import _bulkOperationOptions from "./_bulkOperationOptions";
import _roleOptions from "./_roleOptions";
import _rmaDeclarationViewOptions from "./_rmaDeclarationViewOptions";
import _cellularSimOptions from "./_cellularSimOptions";
import _cellularAccountOptions from "./_cellularAccountOptions";
import _rmaDeclarationOptions from "./_rmaDeclarationOptions";
import _sigfoxAccountOptions from "./_sigfoxAccountOptions";
import _stockOptions from "./_stockOptions";
import _priceModelOptions from "./_priceModelOptions";
import _productRegExpOptions from "./_productRegExpOptions";
import _productAutoUpgradeOptions from "./_productAutoUpgradeOptions";
import _invoicingOperationsOptions from "./_invoicingOperationsOptions";
import _reportTemplateLibraryOptions from "./_reportTemplateLibraryOptions";
import _thirdPartyDataOptions from "./_thirdPartyDataOptions";
import _invoicingOperationsDetailsOptions from "./_invoicingOperationsDetailsOptions";
import _customI18nMessageOptions from "./_customI18nMessageOptions";
import _manageFlowsOptions from "./_manageFlowsOptions";
import _utilizationSchemeOptions from "./_utilizationSchemeOptions";
import _legalDocumentTemplateOptions from "./_legalDocumentTemplateOptions";
import _legalDocumentOptions from "./_legalDocumentOptions";
import _quotationOptions from "./_quotationOptions";
import _invoicesOptions from "./_invoicesOptions";
import _utilizationDashboardOptions from "./_utilizationDashboardOptions";
import _processesOptions from "./_processesOptions";
import _factoryUploadOptions from "./_factoryUploadOptions";
import _partnerTagOptions from "./_partnerTagOptions";
import _manufacturingOptions from "./_manufacturingOptions";
import _manageProcessOptions from "./_manageProcessOptions";
import _maintenanceProgramsOptions from "./_maintenanceProgramsOptions";
import _geolayersOptions from "./_geolayersOptions";
import _geozoneOptions from "./_geozoneOptions";
import _infrastructureOptions from "./_infrastructureOptions";

const getPageOptions = (pageName, loginInfo, orgId, isPartner, isSystem) => {
  let pageOptions = PAGES[pageName].getOptions(
    loginInfo,
    orgId,
    isPartner,
    isSystem
  );

  pageOptions.mapOptions = pageOptions.mapOptions || {};
  pageOptions.pageName = pageName;

  return pageOptions;
};

const SAVE_VIEW_STORAGE_KEY = "SAVE_VIEW_KEY";

const getStoreView = (pageName) => {
  try {
    const storedData =
      JSON.parse(localStorage.getItem(SAVE_VIEW_STORAGE_KEY)) || {};
    return storedData[pageName];
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    return {};
  }
};

const saveStoreView = (id, config, pageName) => {
  let storeSaveView =
    JSON.parse(localStorage.getItem(SAVE_VIEW_STORAGE_KEY)) || {};

  storeSaveView[pageName] = {
    selectedId: id,
    config: config,
    autoSavedView: !id ? config : storeSaveView[pageName]?.autoSavedView,
  };

  localStorage.setItem(SAVE_VIEW_STORAGE_KEY, JSON.stringify(storeSaveView));
};

const deleteStoreView = (pageName) => {
  let storeSaveView =
    JSON.parse(localStorage.getItem(SAVE_VIEW_STORAGE_KEY)) || {};
  delete storeSaveView[pageName];

  localStorage.setItem(SAVE_VIEW_STORAGE_KEY, JSON.stringify(storeSaveView));
};

const getLinkWithSelectedOrgId = (link, selectedOrgId) => {
  if (link) {
    if (link.includes("&org=")) {
      return link;
    } else if (selectedOrgId !== 0 && selectedOrgId !== null) {
      if (link.includes("?")) {
        return `${link}&org=${selectedOrgId}`;
      } else {
        return `${link}?org=${selectedOrgId}`;
      }
    }

    return link;
  }
};

const PAGES = {
  USER: {
    pageName: "USER",
    getOptions: _userOptions,
  },
  ASSET_LIST: {
    pageName: "ASSET_LIST",
    getOptions: _assetListOptions,
  },
  TRACKER_ADMIN: {
    // switch mode map and table
    pageName: "TRACKER_ADMIN",
    getOptions: _trackerAdminOptions,
  },
  GEOLAYERS: {
    // switch mode map and table with simple search
    pageName: "GEOLAYERS",
    getOptions: _geolayersOptions,
  },
  GEOZONE: {
    // switch mode map and table with disable add more filter button
    pageName: "GEOZONE",
    getOptions: _geozoneOptions,
  },
  GEOBEACONS: {
    // INFRASTRUCTURE
    // switch mode map and table
    pageName: "GEOBEACONS",
    getOptions: _infrastructureOptions,
  },
  DEVICE_TAG: {
    pageName: "DEVICE_TAG",
    getOptions: _deviceTagOptions,
  },
  ZENDESK_TICKETS: {
    pageName: "ZENDESK_TICKETS",
    getOptions: _zendeskTicketsOptions,
  },
  SUPPORT_TICKETS: {
    pageName: "SUPPORT_TICKETS",
    getOptions: _zendeskSupportTicketsOptions,
  },
  ORDER_TICKETS: {
    pageName: "ORDER_TICKETS",
    getOptions: _zendeskOrderTicketsOptions,
  },
  MANAGE_ORGANISATION_FILTER: {
    pageName: "MANAGE_ORGANISATION_FILTER",
    getOptions: _organisationFiltersOptions,
  },
  MANAGE_VISIBILITY_FILTER: {
    pageName: "MANAGE_VISIBILITY_FILTER",
    getOptions: _visibilityFiltersOptions,
  },
  PRODUCT: {
    pageName: "PRODUCT",
    getOptions: _productOptions,
  },
  AUTOMATION: {
    pageName: "AUTOMATION",
    getOptions: _automationOptions,
  },
  DASHBOARD: {
    pageName: "DASHBOARD",
    getOptions: _dashboardOptions,
  },
  NOTIFICATION: {
    pageName: "NOTIFICATION",
    getOptions: _notificationOptions,
  },
  ACTIVE_ALERT: {
    pageName: "ACTIVE_ALERT",
    getOptions: _activeAlertOptions,
  },
  HISTORICAL_ALERT: {
    pageName: "HISTORICAL_ALERT",
    getOptions: _historicalAlertOptions,
  },
  MANAGE_ALERT_RULE: {
    pageName: "MANAGE_ALERT_RULE",
    getOptions: _alertRuleOptions,
  },
  AUDIT_LOG: {
    // advanced search filter And date range filter
    pageName: "AUDIT_LOG",
    getOptions: _auditLogOptions,
  },
  GEOZONE_VISIT_LIST: {
    // advanced search filter And date range filter
    pageName: "GEOZONE_VISIT_LIST",
    getOptions: _geozoneVisitOptions,
  },
  MANUFACTURING: {
    // simple search and date range filter
    pageName: "MANUFACTURING",
    getOptions: _manufacturingOptions,
  },
  EXTERNAL_BLE_SENSOR: {
    pageName: "EXTERNAL_BLE_SENSOR",
    getOptions: _externalBLESensorOptions,
  },
  SYSTEM_TAG: {
    pageName: "SYSTEM_TAG",
    getOptions: _systemTagOptions,
  },
  GEOZONE_TAG_MANAGE: {
    pageName: "GEOZONE_TAG_MANAGE",
    getOptions: _geozoneTagOptions,
  },
  EOL_DEVICE_PAGE: {
    pageName: "EOL_DEVICE_PAGE",
    getOptions: _eolDeviceOptions,
  },
  METAR_WEATHER_LOCATION: {
    pageName: "METAR_WEATHER_LOCATION",
    getOptions: _metarWeatherLocationOptions,
  },
  PRIVATE_CELLLAR: {
    pageName: "PRIVATE_CELLLAR",
    getOptions: _privateCellularOptions,
  },
  DEVICE_USAGE_PROFILE: {
    pageName: "DEVICE_USAGE_PROFILE",
    getOptions: _deviceUsageProfileOptions,
  },
  PARTNER: {
    pageName: "PARTNER",
    getOptions: _partnerOptions,
  },
  ORGANISATION: {
    pageName: "ORGANISATION",
    getOptions: _organisationOptions,
  },
  LEGAL_DOCUMENT: {
    pageName: "LEGAL_DOCUMENT",
    getOptions: _legalDocumentOptions,
  },
  INVOICES: {
    pageName: "INVOICES",
    getOptions: _invoicesOptions,
  },
  QUOTATION: {
    pageName: "QUOTATION",
    getOptions: _quotationOptions,
  },
  BULK_OPERATION_REQUEST: {
    // auto reload
    pageName: "BULK_OPERATION_REQUEST",
    getOptions: _bulkOperationOptions,
  },
  ROLE_MANAGER: {
    pageName: "ROLE_MANAGER",
    getOptions: _roleOptions,
  },
  PARTNER_TAG: {
    pageName: "PARTNER_TAG",
    getOptions: _partnerTagOptions,
  },
  MAINTENANCE_PROGRAMS: {
    pageName: "MAINTENANCE_PROGRAMS",
    getOptions: _maintenanceProgramsOptions,
  },
  CELLULAR_SIM: {
    pageName: "CELLULAR_SIM",
    getOptions: _cellularSimOptions,
  },
  CELLULAR_ACCOUNT: {
    pageName: "CELLULAR_ACCOUNT",
    getOptions: _cellularAccountOptions,
  },
  RMA_DECLARATION: {
    pageName: "RMA_DECLARATION",
    getOptions: _rmaDeclarationOptions,
  },
  RMA_DECLARATION_VIEW: {
    // top content
    pageName: "RMA_DECLARATION_VIEW",
    getOptions: _rmaDeclarationViewOptions,
  },
  DEV_ACCOUNT: {
    // simple search
    pageName: "DEV_ACCOUNT",
    getOptions: _devAccountOptions,
  },
  FACTORY_UPLOAD: {
    // simple search
    pageName: "FACTORY_UPLOAD",
    getOptions: _factoryUploadOptions,
  },
  UTILIZATION_SCHEME: {
    // simple search
    pageName: "UTILIZATION_SCHEME",
    getOptions: _utilizationSchemeOptions,
  },
  LEGAL_DOCUMENT_TEMPLATE: {
    // simple search
    pageName: "LEGAL_DOCUMENT_TEMPLATE",
    getOptions: _legalDocumentTemplateOptions,
  },
  INVOICING_OPERATIONS: {
    // simple search
    pageName: "INVOICING_OPERATIONS",
    getOptions: _invoicingOperationsOptions,
  },
  INVOICING_OPERATIONS_DETAILS: {
    // simple search
    pageName: "INVOICING_OPERATIONS_DETAILS",
    getOptions: _invoicingOperationsDetailsOptions,
  },
  WEBHOOKS: {
    // simple search
    pageName: "WEBHOOKS",
    getOptions: _webhooksOptions,
  },
  API_ACCESS: {
    // simple search
    pageName: "API_ACCESS",
    getOptions: _apiAccessOptions,
  },
  ORG_DEMO_SETTINGS: {
    // simple search
    // page with auto reload
    pageName: "ORG_DEMO_SETTINGS",
    getOptions: _organisationDemoOptions,
  },
  GEOLAYER_GENERATOR: {
    // simple filter
    // page with auto reload
    pageName: "GEOLAYER_GENERATOR",
    getOptions: _geolayerGeneratorOptions,
  },
  REPORT: {
    // table with defaultSort setting (not first column sort)
    // page with auto reload
    pageName: "REPORT",
    getOptions: _reportOptions,
  },
  REPORT_SCHEDULE: {
    // client table with simple search
    pageName: "REPORT_SCHEDULE",
    getOptions: _reportScheduleOptions,
  },
  PROCESSES: {
    // client table and emptyData
    pageName: "PROCESSES",
    getOptions: _processesOptions,
  },
  MANAGE_PROCESS: {
    // client table and emptyData
    pageName: "MANAGE_PROCESS",
    getOptions: _manageProcessOptions,
  },
  UTILIZATION_DASHBOARD: {
    // client table with top content and emptyData
    pageName: "UTILIZATION_DASHBOARD",
    getOptions: _utilizationDashboardOptions,
  },
  SIGFOX_ACCOUNT: {
    // client table with simple search
    pageName: "SIGFOX_ACCOUNT",
    getOptions: _sigfoxAccountOptions,
  },
  STOCK: {
    // client table with simple search
    pageName: "STOCK",
    getOptions: _stockOptions,
  },
  MANAGE_FLOWS: {
    // client table with simple search
    pageName: "MANAGE_FLOWS",
    getOptions: _manageFlowsOptions,
  },
  PRICE_MODEL: {
    // client table with simple search
    pageName: "PRICE_MODEL",
    getOptions: _priceModelOptions,
  },
  CUSTOM_I18N_MESSAGES: {
    // client table with simple search
    pageName: "CUSTOM_I18N_MESSAGES",
    getOptions: _customI18nMessageOptions,
  },
  PRODUCT_REGEXP_FILTER: {
    // client table with simple search
    pageName: "PRODUCT_REGEXP_FILTER",
    getOptions: _productRegExpOptions,
  },
  PRODUCT_AUTO_UPGRADE: {
    // client table with simple search
    pageName: "PRODUCT_AUTO_UPGRADE",
    getOptions: _productAutoUpgradeOptions,
  },
  REPORT_TEMPLATE_LIBRARY: {
    // client table with simple search
    pageName: "REPORT_TEMPLATE_LIBRARY",
    getOptions: _reportTemplateLibraryOptions,
  },
  THIRD_PARTY_DATA: {
    // client table with simple search
    pageName: "THIRD_PARTY_DATA",
    getOptions: _thirdPartyDataOptions,
  },
  MAINTENANCE_PROGRAMS_STATISTICS: {
    pageName: "MAINTENANCE_PROGRAMS_STATISTICS",
    getOptions: _maintenanceProgramsStatisticsOptions,
  },
  MAINTENANCE_UPCOMING_TABLE: {
    pageName: "MAINTENANCE_UPCOMING_TABLE",
    getOptions: _maintenanceUpcomingOptions,
  },
  MAINTENANCE_DONE_TABLE: {
    pageName: "MAINTENANCE_DONE_TABLE",
    getOptions: _maintenanceDoneOptions,
  },
};

const DEVICE_SUMMARY_KEY = "deviceStateSummary";

export {
  PAGES,
  getPageOptions,
  getLinkWithSelectedOrgId,
  // actions on store
  getStoreView,
  saveStoreView,
  deleteStoreView,
  DEVICE_SUMMARY_KEY,
};
