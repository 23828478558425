const _customI18nMessageOptions = (loginInfo, orgId, isPartner) => {
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "originalValue",
        "value",
        "key",
        "ownedByOrg",
        "createdBy",
        "createdAt",
        "updatedAt",
      ],
      searchKeys: ["value"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _customI18nMessageOptions;
