import wifiCellularMismatchClient from "@/apis/wifiCellularMismatchClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../components/SntDialog/SntDialog";
import MapUpdateWifiCellularMismatch from "./MapUpdateWifiCellularMismatch";
import MismatchUpdateDialog from "./MismatchUpdateDialog";

const UpdateMismatchPopup = ({
  selectedItems,
  onClose,
  isShow,
  setIsShow,
  onDone,
}) => {
  const language = useSelector((state) => state.language);
  const [actualLocation, setActualLocation] = useState(null);
  const [items, setItems] = useState([]);
  const [isShowResponse, setShowResponse] = useState(false);
  const [responseText, setResponseText] = useState("");

  useEffect(() => {
    if (selectedItems?.length && isShow) {
      wifiCellularMismatchClient.getByIds(selectedItems).then((response) => {
        setItems(response.data);
      });
    }
  }, [selectedItems, isShow]);

  const onSave = () => {
    if (actualLocation && items[0]) {
      wifiCellularMismatchClient
        .createPrivateCellularTower(items[0].id, actualLocation)
        .then(() => {
          setResponseText(language.update_success_key);
          onDone && onDone();
        })
        .catch((e) => {
          setResponseText(language.save_fail + ". " + e.response.data.message);
        })
        .finally(() => {
          setShowResponse(true);
        });
    }
    setIsShow(false);
  };

  const onKeepCurrentTowerLocation = () => {
    wifiCellularMismatchClient
      .delete(items[0].id, true)
      .then(() => {
        setResponseText(language.update_success_key);
        onDone && onDone();
      })
      .catch((e) => {
        setResponseText(language.save_fail + ". " + e.response.data.message);
      })
      .finally(() => {
        setShowResponse(true);
      });
    setIsShow(false);
  };

  return (
    <>
      <MismatchUpdateDialog
        isAllowClickOutside={true}
        isShow={isShow}
        onSave={onSave}
        onKeepCurrentTowerLocation={onKeepCurrentTowerLocation}
        onClose={onClose}
        title={
          language.resolve_wifi_cellular_mismatch_key +
          "(" +
          selectedItems.length +
          " points)"
        }
        closeTxt={language.cancel_key}
      >
        {() => (
          <MapUpdateWifiCellularMismatch
            items={items}
            onClick={(data) =>
              setActualLocation({ lat: data.latitude, lng: data.longitude })
            }
          />
        )}
      </MismatchUpdateDialog>

      <SntDialog
        isShow={isShowResponse}
        onSave={() => {
          setShowResponse(false);
        }}
        onClose={() => {
          setShowResponse(false);
        }}
        title={language.notifications_key}
        saveTxt={"OK"}
        isShowCloseButton={false}
        btnSaveClassName="submit btn-sensolus"
      >
        {() => <div>{responseText}</div>}
      </SntDialog>
    </>
  );
};

export default UpdateMismatchPopup;
