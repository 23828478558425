import { useState } from "react";
import SntDialog from "../..//components/SntDialog/SntDialog";
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox.js";
import SntInfoText from "@/components/SntInfo/SntInfoText";

/**
 * Custom hook for managing delete and error dialogs.
 * @param {object} props - The hook properties.
 * @param {function} props.onDelete - The callback function to handle the delete action.
 * @param {object} [props.language={}] - The language-specific strings or translations.
 * @param {string} [props.title] - The custom title for the delete dialog.
 * @param {string} [props.erroTitle] - The custom title for the error dialog.
 * @returns {object} - The hook functions and components.
 */
const useDeleteAllDialog = ({
  onDelete,
  language = {},
  title,
  saveTxt,
  erroTitle,
  allTitle,
}) => {
  // State for delete dialog
  const [deleteObject, setDeleteObject] = useState(null);
  const [deleteContent, setDeleteContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  // State for error dialog
  const [errorContent, setErrorContent] = useState("");
  const [isOpenError, setIsOpenError] = useState(false);

  // Function to open the delete dialog
  const handleOpen = (object, content) => {
    setDeleteContent(content || language.delete_message);
    setDeleteObject(object);
    setIsOpen(true);
  };

  // Function to close the delete dialog
  const handleClose = () => {
    setIsOpen(false);
  };

  // Function to handle the delete action
  const handleDelete = () => {
    onDelete(deleteObject, deleteAll);
  };

  // Function to open the error dialog
  const handleOpenError = (content) => {
    handleClose();
    setErrorContent(content || language.error_key);
    setIsOpenError(true);
  };

  // Function to close the error dialog
  const handleCloseError = () => {
    setIsOpenError(false);
  };

  // DeleteDialog component
  const DeleteDialog = () => {
    return (
      <SntDialog
        isShow={isOpen}
        onSave={handleDelete}
        onClose={handleClose}
        saveTxt={saveTxt || language.delete_key}
        btnSaveClassName="danger"
        title={title || language.delete_key}
      >
        {() => (
          <>
            <div>{deleteContent}</div>
            <div className="col-xs-12" style={{ display: "flex" }}>
              <SntCheckBox
                checked={deleteAll}
                label={allTitle}
                onChange={(e) => setDeleteAll(e)}
              />
            </div>
            <div className="col-xs-12">
              <SntInfoText>{language.mismatch_delete_info}</SntInfoText>
            </div>
          </>
        )}
      </SntDialog>
    );
  };

  // ErrorDialog component
  const ErrorDialog = () => {
    return (
      <SntDialog
        isShow={isOpenError}
        onSave={handleCloseError}
        onClose={handleCloseError}
        saveTxt={language.ok_key}
        btnSaveClassName="danger"
        isShowCloseButton={false}
        title={erroTitle || language.error_key}
      >
        {() => <div>{errorContent}</div>}
      </SntDialog>
    );
  };

  // Combined component that renders both delete and error dialogs
  const DeleteDialogComponent = () => (
    <>
      <DeleteDialog />
      <ErrorDialog />
    </>
  );

  // Return the functions and components as an object
  return {
    handleOpen, // Function to open the delete dialog
    handleClose, // Function to close the delete dialog
    handleOpenError, // Function to open the error dialog
    handleCloseError, // Function to close the error dialog
    DeleteDialog: DeleteDialogComponent, // Component that renders both delete and error dialogs
  };
};

export default useDeleteAllDialog;
