const FilterUtils = {
  addOrgInSidebarToFilter: (params, orgId) => {
    if (!orgId) return;

    // remove old
    params.query = params.query.filter(function (param) {
      return param.filterKey !== "organizations";
    });

    // add new
    params.query.push({
      filterKey: "organizations",
      filterType: "MULTI_SELECT_AUTO_COMPLETE",
      searchType: "STATIC",
      notFilter: false,
      filterValue: {
        includeEmpty: false,
        includeNoEmpty: false,
        selectedValues: [orgId],
      },
    });
  },
};

export default FilterUtils;
