import saveViewClient from "@/apis/saveViewClient";
import { createContext, useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import {
  deleteStoreView,
  getPageOptions,
  getStoreView,
  saveStoreView,
} from "./pageUtils/PageUtils";

// Create Context
const SaveViewStateContext = createContext();

const defaultState = {
  filter: { searchQueryType: "BASIC", query: [] },
  table: {
    columns: [],
    pageSize: 25,
    pageIndex: 0,
  },
  map: {
    showCellLabels: false,
  },
  switchMode: "TABLE",
  favourite: false,
};

// Provider Component
const SaveViewProvider = ({
  children,
  initialState = {
    pageName: "",
    switchMode: "TABLE",
    orgId: null,
    isPartner: null,
    isSystem: null,
  },
}) => {
  const loginInfo = useSelector((state) => state.user);

  const [saveViewItems, setSaveViewItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [saveViewState, setSaveViewState] = useState({
    ...defaultState,
    pageName: initialState?.pageName,
    switchMode: initialState?.switchMode || defaultState.switchMode,
  });
  const saveViewStateRef = useRef();

  const [compareState, setCompareState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState(null);
  const [mapOptions, setMapOptions] = useState(null);
  const [tableOptions, setTableOptions] = useState(null);

  const [isFilterValid, setIsFilterValid] = useState(false);
  const [isTableValid, setIsTableValid] = useState(false);
  const [isMapValid, setIsMapValid] = useState(false);

  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [forceTableAction, setForceTableAction] = useState(null);
  const [forceMapAction, setForceMapAction] = useState(null);

  const [filterMap, setFilterMap] = useState(null);
  const [presentFilters, setPresentFilters] = useState([]);
  const [serverColumnDef, setServerColumnDef] = useState({});

  const lastAutoSavedViewRef = useRef(null);
  const requestedTableColumnsRef = useRef([]);
  const requestedMapColumnsRef = useRef([]);

  useEffect(() => {
    saveViewStateRef.current = saveViewState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveViewState]);

  useEffect(() => {
    loadItems((data) => {
      setSaveViewItems(data);

      let pageOptions = getPageOptions(
        initialState?.pageName,
        loginInfo,
        initialState.orgId,
        initialState.isPartner,
        initialState.isSystem
      );

      let columnVisibleInOrder = [],
        mapColumnVisibleInOrder = [],
        groups = [],
        orderData = null,
        showCellLabels = false;

      const storedData = getStoreView(initialState?.pageName);
      if (storedData) {
        let findOne = data.find((item) => item.id === storedData.selectedId);
        setSelectedId(storedData.selectedId || null);

        if (findOne) {
          findOne.table.columns = findOne.table.columns
            ? findOne.table.columns.map((item) => {
                return {
                  ...item,
                  aggregateColumn: item.aggregateColumn || null,
                  bucketConfig: item.bucketConfig || null,
                  bucketType: item.bucketType || null,
                  columnGroupKey: item.columnGroupKey || null,
                  columnKey: item.columnKey || null,
                  order: item.order || null,
                };
              })
            : [];
          setCompareState({ ...findOne });
          // } else {
          //   setCompareState({
          //     ...compareState,
          //     pageName: initialState?.pageName,
          //   });
        }

        lastAutoSavedViewRef.current = storedData.autoSavedView;

        let config = storedData.config;
        if (config) {
          config.filter = config.filter || saveViewState.filter;
          config.table = config.table || saveViewState.table;
          config.map = config.map || saveViewState.map;
          setSaveViewState({
            ...config,
            pageName: initialState?.pageName,
          });

          let { table, map } = config;
          if (table?.columns) {
            columnVisibleInOrder = table.columns.map((item) => item.columnKey);

            groups = table.columns
              .filter((item) => item.columnGroupKey)
              .map((item) => ({
                ...item,
                aggregateColumn: item.columnGroupKey,
              }));

            table.columns.forEach((item) => {
              if (item.order) {
                orderData = [item.columnKey, item.order];
              }
            });
          }

          if (map?.columns) {
            mapColumnVisibleInOrder = map.columns.map((item) => item.columnKey);
            showCellLabels = map.showCellLabels;
          }
        }
      }

      setMapOptions({
        ...pageOptions.mapOptions,
        restrictedFilters: pageOptions.filterOptions.restrictedFilters,
        columnVisibleInOrder: mapColumnVisibleInOrder,
        showCellLabels: showCellLabels,
      });
      setFilterOptions(pageOptions.filterOptions);
      setTableOptions({
        ...pageOptions.tableOptions,
        restrictedFilters: pageOptions.filterOptions.restrictedFilters,
        orderData: orderData,
        columnVisibleInOrder: columnVisibleInOrder,
        groups: groups,
      });
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddSaveView = (item) => {
    loadItems((data) => {
      setSaveViewItems(data);

      let selectedItem = data.find((obj) => obj.id === item.id);

      if (selectedItem) {
        setSelectedId(selectedItem.id);
        setSaveViewState(selectedItem);
        setCompareState(selectedItem);
      }
    });
  };

  const onEditSaveView = (item) => {
    let findOne = saveViewItems.find((obj) => obj.id === item.id);
    if (findOne) {
      findOne.title = item.title;
      findOne.favourite = item.favourite;
      setSaveViewItems([...saveViewItems]);
    }
  };

  const onDeleteSaveView = (item) => {
    setSaveViewItems(saveViewItems.filter((obj) => obj.id !== item.id));
  };

  const onReloadSaveViewItems = () => {
    loadItems((data) => {
      setSaveViewItems(data);
    });
  };

  const handleUpdateSaveView = (data, successCallback, errorCallback) => {
    let params = { ...data };
    if (!params.filterConfig && params.filter) {
      params.filterConfig = JSON.stringify(params.filter);
      delete params.filter;
    }

    if (!params.mapConfig && params.map) {
      params.mapConfig = JSON.stringify(params.map);
      delete params.map;
    }

    if (!params.tableConfig && params.table) {
      params.tableConfig = JSON.stringify(params.table);
      delete params.table;
    }

    saveViewClient
      .update(params)
      .then(({ data }) => {
        let item = convertServerData(data);
        successCallback && successCallback(item);
      })
      .catch((error) => {
        errorCallback && errorCallback(error);
      });
  };

  const handleDeleteSaveView = (params, successCallback, errorCallback) => {
    saveViewClient
      .delete(params.id)
      .then((data) => {
        successCallback && successCallback(params);
      })
      .catch((error) => {
        errorCallback && errorCallback(error);
      });
  };

  const getOldStructureOptionSettings = () => {
    let switchMode = saveViewState.switchMode,
      table = saveViewState.table;

    let columnVisibleInOrder = [],
      boundsConfig,
      groups = [],
      orderData = [];

    if (switchMode === "TABLE") {
      if (table?.columns) {
        columnVisibleInOrder = table.columns.map((item) => item.columnKey);

        groups = table.columns
          .filter((item) => item.columnGroupKey)
          .map((item) => ({
            ...item,
            aggregateColumn: item.columnGroupKey,
          }));
        table.columns.forEach((item) => {
          if (item.order) {
            orderData = [item.columnKey, item.order];
          }
        });
      }
    } else if (switchMode === "MAP") {
      // TODO: get config from map settings
    }

    let currentBounds = saveViewState.map?.currentBounds;
    if (currentBounds) {
      boundsConfig = {
        currentBounds: currentBounds,
        queryBounds: {
          filterKey: "locationByBound",
          filterType: "SPATIAL",
          categoryId: "locationInfo",
          searchType: "STATIC",
          filterValue: {
            xmin: currentBounds._southWest.lat,
            ymin: currentBounds._southWest.lng,
            xmax: currentBounds._northEast.lat,
            ymax: currentBounds._northEast.lng,
          },
        },
      };
    }

    let optionsSettings = {
      switchMode: switchMode,
      searchQueryType: saveViewState.filter.searchQueryType,
      query: saveViewState.filter.query,
      tableSettings: {
        columnVisibleInOrder,
        orderData,
        groups,
      },
      boundsConfig: boundsConfig,
    };

    return optionsSettings;
  };

  const convertServerData = (item) => {
    return {
      id: item.id,
      pageName: item.pageName,
      switchMode: item.switchMode,
      title: item.title,
      favourite: item.favourite,
      filter: item.filterConfig
        ? JSON.parse(item.filterConfig)
        : defaultState.filter,
      table: item.tableConfig
        ? JSON.parse(item.tableConfig)
        : defaultState.table,
      map: item.mapConfig ? JSON.parse(item.mapConfig) : defaultState.map,
    };
  };

  const loadItems = async (callback) => {
    try {
      const dataList = await saveViewClient.getAll(initialState?.pageName);
      let data = dataList.data.map((item) => {
        return convertServerData(item);
      });

      callback(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectSaveView = (id) => {
    const item = saveViewItems.find((i) => i.id === id);
    saveStoreView(id, item, initialState.pageName);
  };

  const handleResetSaveViewToDefault = () => {
    deleteStoreView(initialState?.pageName);
  };

  const handleResetToAutosavedView = () => {
    saveStoreView(null, lastAutoSavedViewRef.current, initialState?.pageName);
  };

  const getNewCols = (filterUpdate) => {
    let currentFilterKeys = filterUpdate.query.map((q) => q.filterKey).sort();
    let newFilterKeys = [];
    if (
      JSON.stringify(saveViewState.filter.query) !==
      JSON.stringify(currentFilterKeys)
    ) {
      currentFilterKeys.forEach((key) => {
        if (
          saveViewState.filter.query.map((q) => q.filterKey).indexOf(key) === -1
        ) {
          newFilterKeys.push(key);
        }
      });
    }

    let newColumns = [];

    // add new columns from new filter keys
    newFilterKeys.forEach((filterKey) => {
      for (let key in serverColumnDef) {
        if (serverColumnDef[key].filterKeys.indexOf(filterKey) !== -1) {
          newColumns.push(key);
          continue;
        }
      }
    });

    return newColumns;
  };

  const updateFilter = (filterUpdate, isFilterValid = false) => {
    let isChange =
      JSON.stringify(saveViewState.filter) !== JSON.stringify(filterUpdate);

    if (isChange) {
      setSaveViewState((prev) => ({
        ...prev,
        filter: { ...prev.filter, ...filterUpdate },
      }));
    }

    setIsFilterValid(isFilterValid);
  };

  const updateTable = (tableUpdate, isTableValid = false) => {
    if (!tableUpdate) {
      // for page asset map
      setIsTableValid(true);
      return;
    }

    let { requestedTableColumns, ...rest } = tableUpdate;
    requestedTableColumnsRef.current = requestedTableColumns;

    rest.columns = rest.columns.map((item) => {
      return {
        ...item,
        aggregateColumn: item.aggregateColumn || null,
        bucketConfig: item.bucketConfig || null,
        bucketType: item.bucketType || null,
        columnGroupKey: item.columnGroupKey || null,
        columnKey: item.columnKey || null,
        order: item.order || null,
      };
    });

    let isChange =
      JSON.stringify(saveViewState.table.columns) !==
        JSON.stringify(rest.columns) ||
      saveViewState.table.pageIndex != rest.pageIndex ||
      saveViewState.table.pageSize != rest.pageSize;

    if (isChange) {
      setSaveViewState((prev) => ({
        ...prev,
        table: { ...prev.table, ...rest },
      }));
    }
    setIsTableValid(isTableValid);
  };

  const refreshData = () => {
    setSaveViewState({ ...saveViewState });
  };

  const updateMap = (mapUpdate, isMapValid = false) => {
    if (!mapUpdate) {
      // for page asset map
      setIsMapValid(true);
      return;
    }

    let { requestedTableColumns } = mapUpdate;
    requestedMapColumnsRef.current = requestedTableColumns;

    let isChange =
      JSON.stringify(saveViewState.map) !== JSON.stringify(mapUpdate);

    if (isChange) {
      setSaveViewState((prev) => {
        let { requestedTableColumns, currentBounds } = mapUpdate;
        let updatedQuery = prev.filter.query;
        requestedMapColumnsRef.current = requestedTableColumns;

        if (currentBounds?._southWest && currentBounds?._northEast) {
          const newFilterValue = {
            xmin: currentBounds._southWest.lat,
            ymin: currentBounds._southWest.lng,
            xmax: currentBounds._northEast.lat,
            ymax: currentBounds._northEast.lng,
          };

          const index = updatedQuery.findIndex(
            (q) => q.filterKey === "locationByBound"
          );
          updatedQuery = [...updatedQuery]; // clone to avoid mutating original
          if (index !== -1) {
            updatedQuery[index] = {
              ...updatedQuery[index],
              filterValue: newFilterValue,
            };
          } else {
            if (updatedQuery.config?.switchOn) {
              updatedQuery = [
                ...updatedQuery,
                {
                  filterKey: "locationByBound",
                  filterType: "SPATIAL",
                  categoryId: "locationInfo",
                  searchType: "STATIC",
                  filterValue: newFilterValue,
                },
              ];
            }
          }
        }

        return {
          ...prev,
          map: { ...prev.map, ...mapUpdate },
          filter: {
            ...prev.filter,
            query: updatedQuery,
          },
        };
      });
    }
    setIsMapValid(isMapValid);
  };

  const updateBoundFilter = (filterUpdate, isFilterValid = false) => {
    let isChange =
      JSON.stringify(saveViewStateRef.current.filter) !==
      JSON.stringify(filterUpdate);

    if (isChange) {
      setSaveViewState((prev) => ({
        ...prev,
        filter: { ...prev.filter, ...filterUpdate },
      }));
    }

    setIsFilterValid(isFilterValid);
  };

  const updateSwitchMode = (switchMode) => {
    setSaveViewState((prev) => ({
      ...prev,
      switchMode: switchMode,
    }));
  };

  useEffect(() => {
    if (compareState !== null) {
      setIsChanged(
        JSON.stringify(saveViewState) !== JSON.stringify(compareState)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveViewState, compareState]);

  useEffect(() => {
    if (initialState.pageName && isChanged) {
      saveStoreView(selectedId, saveViewState, initialState.pageName);
      if (!selectedId) {
        lastAutoSavedViewRef.current = saveViewState;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveViewState, selectedId, isChanged]);

  useEffect(() => {
    if (isMapValid && isFilterValid && !compareState) {
      setCompareState({ ...saveViewState });
    }
    if (isTableValid && isFilterValid && !compareState) {
      setCompareState({ ...saveViewState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterValid, isTableValid, isMapValid, compareState]);

  useEffect(() => {
    let query = saveViewState.filter?.query || [];

    let presentWithValueKey = [];
    query.forEach((item) => {
      let { filterValue, filterType } = item;

      if (item.notFilter) {
        presentWithValueKey.push(item.filterKey);
      } else if (filterValue) {
        if (filterType === "MULTI_SELECT_CHECKBOX") {
          let selectedValues = filterValue.selectedValues || [];
          let operator = filterValue.operator;
          if (selectedValues.length === 0 && (!operator || operator === "IN"))
            return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "STRING") {
          let { includeEmpty, includeNoEmpty, searchText } = filterValue;
          if (!searchText && !includeEmpty && !includeNoEmpty) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "MULTI_STRING") {
          let { searchText } = filterValue;
          if (!searchText) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "BOOLEAN") {
          let { value, naOnly } = filterValue;
          if (value === null && !naOnly) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "NUMERIC" || filterType === "DATE") {
          presentWithValueKey.push(item.filterKey);
        }
      }
    });
    setPresentFilters(presentWithValueKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveViewState.filter]);

  return (
    <SaveViewStateContext.Provider
      value={{
        saveViewState,
        requestedTableColumns: requestedTableColumnsRef.current,
        requestedMapColumns: requestedMapColumnsRef.current,
        selectedId,
        isChanged,
        saveViewItems,
        isLoading,
        isFilterValid,
        isTableValid,
        isMapValid,
        filterOptions,
        tableOptions,
        mapOptions,

        updateSwitchMode,
        updateMap,
        updateTable,
        updateFilter,
        updateBoundFilter,
        onAddSaveView,
        onEditSaveView,
        onDeleteSaveView,
        onSelectSaveView,
        handleResetSaveViewToDefault,
        handleResetToAutosavedView,
        onReloadSaveViewItems,

        refreshData,

        // BE actions
        loadItems,
        handleUpdateSaveView,
        handleDeleteSaveView,

        forceFilterAction,
        setForceFilterAction,
        forceTableAction,
        setForceTableAction,
        forceMapAction,
        setForceMapAction,

        // support actions
        getOldStructureOptionSettings,
        presentFilters,
        filterMap,
        setFilterMap,
        serverColumnDef,
        setServerColumnDef,
        getNewCols,
      }}
    >
      {children}
    </SaveViewStateContext.Provider>
  );
};

// const omitRequestedTableColumns = (state) => {
//   const { table, ...rest } = state;
//   const { requestedTableColumns, ...tableWithoutRequested } = table;
//   return {
//     ...rest,
//     table: tableWithoutRequested,
//   };
// };

const useSaveView = () => useContext(SaveViewStateContext);

export default useSaveView;
export { SaveViewProvider };
