import { useCallback, useEffect, useRef, useState } from "react";
import useOuterClick from "../useOutsideElement";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import usePositionFilter from "../usePositionFilter";
import CommonMapReact from "@/cleanup/containers/CommonMapReact/CommonMapReact";
import L from "leaflet";
import AlertSwitch from "@/cleanup/pages/AlertRule/AlertSwitch";

const BoundFilter = ({ descriptor, data = {}, onChange, disabled = false }) => {
  const language = useSelector((state) => state.language);
  const { isMobile } = useSelector((state) => state.all);
  const [isShow, setShow] = useState(false);
  const map = useRef();

  const [dataState, setDataState] = useState({
    searchText: "",
    includeEmpty: false,
    includeNoEmpty: false,
  });

  const [active, setActive] = useState(false);

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  const originalBoundsRef = useRef(null);
  const rectangleRef = useRef(null); // Ref to store the rectangle

  const hasUserInteracted = useRef(false);
  const isMapReady = useRef(false);

  useEffect(() => {
    const { filterValue, config } = data;

    if (config && config.switchOn !== undefined && config.switchOn !== null) {
      setActive(config.switchOn);
    }

    if (isShow && map.current && map.current.invalidateSize) {
      map.current.invalidateSize();

      setTimeout(() => {
        if (
          filterValue &&
          filterValue.xmin !== undefined &&
          filterValue.ymin !== undefined &&
          filterValue.xmax !== undefined &&
          filterValue.ymax !== undefined
        ) {
          // If bounds exist, fit to them
          const bounds = L.latLngBounds(
            L.latLng(filterValue.xmin, filterValue.ymin),
            L.latLng(filterValue.xmax, filterValue.ymax)
          );
          originalBoundsRef.current = bounds;

          const leafletMap = map.current;

          if (rectangleRef.current) {
            leafletMap.removeLayer(rectangleRef.current);
          }
          if (originalBoundsRef.current && active) {
            const bounds = originalBoundsRef.current;

            rectangleRef.current = L.rectangle(bounds, {
              color: "#2785db", // Border color
              weight: 1, // Border thickness
              fillColor: "#f7f3a1",
              fillOpacity: 0.3,
              interactive: false,
            }).addTo(leafletMap);
          }

          map.current.fitBounds(bounds);
        } else {
          // Otherwise, fit to the whole world
          const worldBounds = L.latLngBounds(
            L.latLng(-90, -180),
            L.latLng(90, 180)
          );
          map.current.fitBounds(worldBounds, {
            padding: [10, 10],
            maxZoom: 0,
          });
          originalBoundsRef.current = worldBounds;
        }
      }, 100); // Give it a moment to render properly
    }

    let _dataState = {
      searchText: "",
      includeEmpty: false,
      includeNoEmpty: false,
    };

    if (filterValue) {
      _dataState = { ...filterValue };
    }

    setDataState(_dataState);
  }, [isShow, data, data.filterValue, descriptor, language.all_key, active]);

  useEffect(() => {
    const leafletMap = map.current;
    if (!leafletMap) return;

    const markUserInteraction = () => {
      if (isMapReady.current) {
        hasUserInteracted.current = true;
      }
    };

    leafletMap.on("zoom", markUserInteraction);
    leafletMap.on("move", markUserInteraction);

    const markMapReady = () => {
      isMapReady.current = true;
      leafletMap.off("moveend", markMapReady); // Clean up
    };

    // Wait for the initial fitBounds to complete
    leafletMap.on("moveend", markMapReady);

    return () => {
      leafletMap.off("zoom", markUserInteraction);
      leafletMap.off("move", markUserInteraction);
      leafletMap.off("moveend", markMapReady);
    };
  }, [isShow]);

  const getData = useCallback(
    (checked) => {
      let bounds = originalBoundsRef.current;

      let newData = JSON.parse(JSON.stringify(data));
      newData.filterValue = {
        includeEmpty: dataState.includeEmpty,
        includeNoEmpty: dataState.includeNoEmpty,
        xmin: bounds?._southWest.lat,
        ymin: bounds?._southWest.lng,
        xmax: bounds?._northEast.lat,
        ymax: bounds?._northEast.lng,
      };

      newData.config = { switchOn: checked };

      return newData;
    },
    [data, dataState]
  );

  function isFilterChanged(oldDataFilter, newDataFilter) {
    const keys = new Set([
      ...Object.keys(oldDataFilter),
      ...Object.keys(newDataFilter),
    ]);

    for (const key of keys) {
      if (oldDataFilter[key] !== newDataFilter[key]) {
        return true;
      }
    }

    return false;
  }

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData(active);
    let newDataFilter = newData.filterValue || {};
    return isFilterChanged(oldDataFilter, newDataFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getData, active]);

  let refWrapper = useOuterClick((e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData(active));
    }
  });

  const onClickButton = (e) => {
    hasUserInteracted.current = false;
    isMapReady.current = false;

    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onAppliedFilter = (checked) => {
    onChange && onChange(getData(checked));
  };

  const onSwitchChange = (checked) => {
    setActive(checked);
    onAppliedFilter(checked);
  };

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={descriptor.description}
        disabled={disabled}
        onClick={onClickButton}
      >
        <span>{language.map_key}</span>:{" "}
        <FilterHeaderStyle>
          {active ? language.map_on_key : language.map_off_key}
        </FilterHeaderStyle>
      </Button>
      <FilterBody
        ref={popupRef}
        style={{
          display: isShow ? "block" : "none",
          ...popupStyleRef.current,
        }}
      >
        <Container className="pb-3">
          <div className="mt-3 d-flex flex-row flex-wrap">
            <div className="flex-grow-1 align-self-center">
              <h5>{language.map_key}</h5>
            </div>

            <div className="mb-1 flex-grow-1 align-self-center">
              <div className="float-end">
                <AlertSwitch
                  active={active}
                  id={"bound"}
                  onSwitchChange={onSwitchChange}
                />
              </div>
            </div>
          </div>

          <span>
            <CommonMapReact
              mapRef={map}
              width={isMobile ? "100%" : "40vh"}
              height={isMobile ? "40vh" : "40vh"}
              options={{
                hideCurrentLocation: true,
                hideOverview: true,
                hideZoomControl: true,
                hideFullScreen: true,
                searchBox: false,
                showGeozoneLayer: false,
                showNetworkLayer: false,
              }}
              disableInteractions={true}
            ></CommonMapReact>
          </span>
        </Container>
      </FilterBody>
    </AdvanceFilter>
  );
};

export default BoundFilter;
