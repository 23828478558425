const _visibilityFiltersOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "VISIBILITY_FILTER";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["name", "description", "userCount", "deviceCount"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organizations"],
      columnVisibleInOrder: [],
      visibleCols: [
        "name",
        "description",
        "userCount",
        "deviceCount",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
  return pageOptions;
};

export default _visibilityFiltersOptions;
