const _organisationOptions = (loginInfo, orgId, isPartner) => {
  let { permissionsMap } = loginInfo;
  let queryObjectType = "ORGANISATION";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "organisationName",
        "partner",
        "organisationTags",
        "basePlanKey",
        "status",
      ],
      restrictedFilters: ["organisationFilterDtos"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organizations"],
      columnVisibleInOrder: [],
      visibleCols: [],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  let visibleCols = [
    "id",
    "name",
    "thirdPartyId",
    "organisationType",
    "partnerName",
    "lastLogin",
    "diagnosticStructure_numberOfTrackers",
    "deviceHaveMajor",
    "deviceHaveMinor",
    "platformSubscription",
    "trial",
    "extraFeatures",
    "actions",
  ];
  if (permissionsMap["SYSTEM_ADMIN_VIEW"]) {
    visibleCols = [
      "id",
      "name",
      "thirdPartyId",
      "organisationType",
      "partnerName",
      "lastLogin",
      "diagnosticStructure_numberOfTrackers",
      "deviceHaveMajor",
      "deviceHaveMinor",
      "organisationTags",
      "platformSubscription",
      "trial",
      "extraFeatures",
      "actions",
    ];
  }
  pageOptions.tableOptions.visibleCols = visibleCols;

  return pageOptions;
};

export default _organisationOptions;
