import DateModeView from "@widgets/dates/DateModeView";
import moment from "moment";

const _geozoneVisitOptions = (loginInfo, orgId, isPartner) => {
  const { weekStart } = loginInfo;

  let queryObjectType = "GEOZONE_VISIT_RECORD";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["assetTags", "geozoneTags", "geozone"],
      restrictedFilters: ["id", "geozoneid", "areaThirdPartyId"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
      rangeDates: {
        viewMode: DateModeView.week,
        start: weekStart
          ? moment().startOf("isoWeek")
          : moment().startOf("isoWeek").subtract(1, "days"),
        end: weekStart
          ? moment().endOf("isoWeek")
          : moment().endOf("isoWeek").subtract(1, "days"),
        overrideDisplays: { day: false, year: false },
        custom: { hideRanges: { today: true, yesterday: true } },
        enableTime: true,
      },
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["id"],
      columnVisibleInOrder: [],
      defaultSortBy: ["lastChangeTime", true],
      visibleCols: [
        "deviceName",
        "deviceSerial",
        "geozone",
        "entryTime",
        "exitTime",
        "duration",
        "lastChangeTime",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _geozoneVisitOptions;
