import L from "leaflet";
import $ from "jquery";

L.Control.ControlBoundFilter = L.Control.extend({
  // @section
  // @aka Control.Zoom options
  options: {
    position: "topright",
    title: "Map filter",
  },

  onAdd: function (map) {
    let activated = "leaflet-control-custom-bound-filter-activated";
    let deactivated = "leaflet-control-custom-bound-filter-deactivated";

    var a = $(
      '<div class="' +
        deactivated +
        ' leaflet-bar" title="' +
        this.options.title +
        '"><a class="" javascript:void(0)></a></div>'
    );

    function handleEvent(e) {
      const data = e.detail;

      let isActivated = data.switchOn;
      const oldClass = !isActivated ? activated : deactivated;
      const newClass = !isActivated ? deactivated : activated;
      a.removeClass(oldClass).addClass(newClass);
    }

    this._handleEvent = handleEvent;

    // Listen for the custom event
    document.addEventListener("bound_switch_react_change", handleEvent);

    a.on("click", function (e) {
      map.fire("custom_bound_filter", {
        switchOn: a.hasClass(activated) ? false : true,
      });
    });

    a.on("dblclick", function (e) {
      e.originalEvent.stopPropagation();
    });

    return a[0];
  },

  onRemove: function (map) {
    document.removeEventListener(
      "bound_switch_react_change",
      this._handleEvent
    );
  },
});
export const ControlBoundFilter = function (opts) {
  return new L.Control.ControlBoundFilter(opts);
};
