const _zendeskOrderTicketsOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "ZENDESK_ORDER_TICKET";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["subject", "statusOrder", "createdAt"],
      restrictedFilters: ["organizations"],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organizations"],
      columnVisibleInOrder: [],
      visibleCols: [
        "subject",
        "PONumber",
        "actions", // type_of_units_key
        "statusOrder",
        "shippingAddress",
        "remarks",
        "upsTrackingNumber",
        "createdAt",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
  return pageOptions;
};

export default _zendeskOrderTicketsOptions;
