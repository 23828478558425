const _geozoneOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "AREA";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["geozoneIdentifier", "geozoneTags", "address"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
      isDisableAddFilterBtn: true,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["geozone_coordinates"],
      visibleCols: ["name", "listTagName", "thirdPartyId"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      columnVisibleInOrder: [],
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _geozoneOptions;
