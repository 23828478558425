const _utilizationSchemeOptions = (loginInfo, orgId, isPartner) => {
  let { features, permissionsMap } = loginInfo;
  let pageOptions = {
    filterOptions: {
      searchQueryType: "SIMPLE_SEARCH",
    },
    tableOptions: {
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: ["name", "timezone", "numOfAssets"],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  if (features.visibility_filters && permissionsMap["VISIBILITY_FILTER_VIEW"]) {
    pageOptions.tableOptions.visibleCols = [
      "name",
      "timezone",
      "numOfAssets",
      "visibilityFilters",
    ];
  }

  return pageOptions;
};

export default _utilizationSchemeOptions;
