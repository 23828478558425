const _reportOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "REPORT";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["reportTitle", "type", "status"],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "createdDate",
        "reportTitle",
        "reportType",
        "scheduled",
        "timeRange",
        "status",
        "creationDuration",
        "actions",
      ],
      defaultSortBy: ["createdDate", true],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _reportOptions;
