const _productOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "TRACKER_PRODUCT";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "nonTechKey",
        "key",
        "gpsEnabled",
        "bleEnabled",
        "wifiEnabled",
        "sigfoxEnabled",
        "nbiotEnabled",
      ],
      restrictedFilters: [],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [],
      columnVisibleInOrder: [],
      visibleCols: [
        "key",
        "nonTechKey",
        "descriptorType",
        "deviceCategory",
        "softwareVersion",
        "fwSha",
        "bleEnabled",
        "geoBeaconEnabled",
        "wifiEnabled",
        "sigfoxEnabled",
        "nbiotEnabled",
        "gpsEnabled",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _productOptions;
