const _historicalAlertOptions = (loginInfo, orgId, isPartner) => {
  let queryObjectType = "HISTORICAL_ALERT";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["filter_rule", "filter_severity"],
      restrictedFilters: [
        "tabcol_aggregated_data",
        "alert_monitoredEntity_device_ownedBy_orgId",
        "bound",
        "additional_info",
        "filter_organisation",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [
        "tabcol_aggregated_data",
        "alert_monitoredEntity_device_ownedBy_orgId",
        "actions",
      ],
      columnVisibleInOrder: [],
      visibleCols: [
        "tabcol_monitored_item",
        "tabcol_severity",
        "tabcol_rule",
        "tabcol_raised",
        "tabcol_cleared",
        "tabcol_resolution_time",
        "tabcol_status",
        "tabcol_comment",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  return pageOptions;
};

export default _historicalAlertOptions;
