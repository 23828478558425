import { useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import SntDialog from "@/components/SntDialog/SntDialog";
import { AssetTag } from "@/components/ReactBootstrap/TagMore";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntLink from "@/components/ReactBootstrap/SntLink";
import partnerTagClient from "@/apis/partnerTagClient";
import {
  PAGES,
  saveStoreView,
} from "@/cleanup/containers/SaveViewContainer/pageUtils/PageUtils";
import CanNotDeleteTag from "@/pages/DialogTag/CanNotDeleteTag";
import DeleteTag from "@/pages/DialogTag/DeleteTag";
import TableSaveView from "@/cleanup/containers/SaveViewContainer/TableSaveView";

const PartnerTagListTableSaveView = ({ orgId }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const table = useRef();
  const [itemSelected, setItemSelected] = useState(null);
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);
  const [showWarningDelete, setShowWarningDelete] = useState(false);
  const [numOfDeviceLinked, setNumOfDeviceLinked] = useState(0);
  const [linkedList, setLinkedList] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");

  const onNumAssetClick = (e, _id) => {
    e.preventDefault();
    let _query = {
      categoryId: "assetInfo",
      filterKey: "partnerTags",
      filterType: "MULTI_SELECT_CHECKBOX",
      filterValue: {
        includeEmpty: false,
        selectedValues: [_id],
      },
      searchType: "STATIC",
    };

    saveStoreView(
      null,
      {
        switchMode: "TABLE",
        filter: {
          searchQueryType: "BASIC",
          query: [_query],
        },
      },
      PAGES.ASSET_LIST.pageName
    );

    history.push("tracked_assets?org=" + loginInfo.organisation.id);
  };

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        title: language.name_key,
        Cell: ({ cell, value }) => {
          return (
            <AssetTag
              role="button"
              onClick={(event) => {
                event.preventDefault();
                history.push(
                  `/partner_tag_manage?org=${orgId}&type=view&id=${cell.row.original.id}`
                );
              }}
            >
              {value}
            </AssetTag>
          );
        },
      },
      {
        key: "numAssets",
        title: language.number_of_assets_key,
        Cell: ({ cell, value }) => {
          return value ? (
            <SntLink onClick={(e) => onNumAssetClick(e, cell.row.original.id)}>
              {value}
            </SntLink>
          ) : (
            ""
          );
        },
      },
    ];

    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(() => {
    return [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          let full = cell.row.original;
          return (
            <>
              <SntActionButton
                title={language.edit_key}
                to={`/partner_tag_manage?org=${orgId}&type=edit&id=${cell.row.original.id}`}
              >
                <SntPencilIcon />
              </SntActionButton>
              <SntActionButton
                title={language.delete_key}
                onClick={(e) => onDeleteClicked(e, full)}
              >
                <SntDeleteIcon />
              </SntActionButton>
            </>
          );
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    // prevalidate
    partnerTagClient
      .checkTag(item.id)
      .then((data) => {
        // count number of asset tag
        setNumOfDeviceLinked(data.data || 0);
        setShowDelete(true);
        setItemSelected(item);
      })
      .catch((e) => {
        setLinkedList(e.response.data);
        setShowWarningDelete(true);
      });
  };

  const onDeleteSelectedItem = () => {
    if (itemSelected) {
      partnerTagClient
        .delete(itemSelected.id)
        .then(() => {
          setShowDelete(false);
          setItemSelected(null);
          table.current.refresh();
        })
        .catch((err) => {
          console.log(err);
          setShowDelete(false);
          if (err && err.response && err.response.data) {
            setMessage(err.response.data.message);
            setShowNotification(true);
          }
        });
    }
  };

  return (
    <>
      <SntDialog
        isShow={showNotification}
        onSave={() => {
          setShowNotification(false);
        }}
        title={language.remove_tag}
        saveTxt={language.close_key}
        isShowCloseButton={false}
      >
        {() => <div>{ReactHtmlParser(message)}</div>}
      </SntDialog>
      <DeleteTag
        numLinkedObject={numOfDeviceLinked}
        objectType={language.assets_lower_key}
        isShow={showDelete}
        onDelete={onDeleteSelectedItem}
        onClose={() => setShowDelete(false)}
      />

      <CanNotDeleteTag
        linkedList={linkedList}
        objectType={language.assets_lower_key}
        isShow={showWarningDelete}
        onClose={() => setShowWarningDelete(false)}
      />
      <TableSaveView
        shared={(child) => (table.current = child)}
        allColumns={allColumns}
        extraCols={extraCols}
      />
    </>
  );
};

export default PartnerTagListTableSaveView;
