import SntIcon from "./SntIcon";

const SntSaveViewIcon = (props) => {
  return (
    <SntIcon {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        stroke="#B8BFCA"
        fill="transparent"
      />
      <path d="M4.94 6L8 8.47439L11.06 6L12 6.7655L8 10L4 6.7655L4.94 6Z" />
    </SntIcon>
  );
};

export default SntSaveViewIcon;
