import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import MonitoredAssets from "@/components/MonitoredItemsPanel/MonitoredAssets";
import SntPanel from "@/components/Panel/SntPanel";
import {
  FormFullScreenLayout,
  InputCheck,
  InputText,
  InputTextArea,
  ItemSelector,
} from "@/components/ReactBootstrap/FormValidation";
import { Formik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import SntDialog from "@/components/SntDialog/SntDialog";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { useOrg } from "@/contexts/OrgContext";
import SntOrganizationSelector from "@/components/SntOrganizationSelector/SntOrganizationSelector";

const RMADeclarationManage = ({ reload }) => {
  const language = useSelector((state) => state.language);

  const location = useLocation();
  const { orgId } = useOrg();
  let { id, type: routeType } = queryString.parse(location.search);
  const [ownedByOrg, setOwnedByOrg] = useState({ value: orgId });
  const [loading, setLoading] = useState(false);

  const statusOptions = [
    { value: "NEW", label: language.new_key },
    { value: "ARRIVED", label: language.arrived },
    { value: "UNDER_INVESTIGATION", label: language.under_investigation },
    { value: "CLOSED", label: language.closed_key },
  ];

  const [rmaDeclaration, setRmaDeclaration] = useState({
    zendeskId: "",
    linkToZendesk: "",
    description: "",
    internalDescription: "",
    status: "NEW",
    monitoredDevices: {
      selectType: "ALL",
      selectedIds: [],
    },
    handledByPartner: false,
    deviceSerials: [],
  });

  const history = useHistory();
  const [errorDialogMessage, setErrorDialogMessage] = useState("Error");
  const [isShowErrorDialog, setShowErrorDialog] = useState(false);
  const [completedLoadingData, setCompletedLoadingData] = useState(false);

  useEffect(() => {
    if (id) {
      rmaDeclarationClient.getById(id).then(({ data }) => {
        let d = { ...data };
        d.status = statusOptions.find((o) => (o.value = d.status));
        setRmaDeclaration(d);
        setOwnedByOrg({
          value: d.ownedByOrg.id,
          label: d.ownedByOrg.name,
          type: d.ownedByOrg.organisationType,
        });
        setCompletedLoadingData(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload, routeType]);

  const onSave = (data) => {
    let params = updateData2Server(data);
    if (loading) return;
    setLoading(true);
    if (routeType === "add") {
      rmaDeclarationClient
        .create(params)
        .then(({ data }) => {
          history.push(`/rma_declaration?org=${orgId}`);
          setLoading(false);
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorDialogMessage(error.response.data.message);
          }
          setShowErrorDialog(true);
          setLoading(false);
        });
    } else if (routeType === "edit") {
      rmaDeclarationClient
        .update(id, params)
        .then(({ data }) => {
          history.push(`/rma_declaration?org=${orgId}`);
          setLoading(false);
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorDialogMessage(error.response.data.message);
          }
          setShowErrorDialog(true);
          setLoading(false);
        });
    }
  };

  const updateData2Server = (data) => {
    let params = { ...data };
    params.status = data.status.value;
    params.internalDescription = data.internalDescription;
    return params;
  };

  const validationSchema = Yup.object().shape({
    zendeskId: Yup.string().required(language.valid_required_key),
    linkToZendesk: Yup.string().required(language.valid_required_key),
  });

  return (
    <>
      <PageHeader title={language.device_return} />

      <SntDialog
        isShow={isShowErrorDialog}
        onSave={() => {
          setShowErrorDialog(false);
        }}
        btnSaveClassName="danger"
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.error_key}
      >
        {() => <div>{errorDialogMessage}</div>}
      </SntDialog>

      <Formik
        initialValues={rmaDeclaration}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({ setFieldValue, handleSubmit }) => {
          return (
            <Form className="form-horizontal">
              <SntPanel title={language.return_details}>
                <FormFullScreenLayout
                  label={language.zendesk_support_id}
                  isRequired={true}
                  rightRender={() => (
                    <InputText
                      name="zendeskId"
                      placeholder={language.nazendesk_support_idme_key}
                      disabled={routeType === "edit"}
                    />
                  )}
                />

                <FormFullScreenLayout
                  label={language.zendesk_support_link}
                  isRequired={true}
                  rightRender={() => (
                    <InputText
                      name="linkToZendesk"
                      placeholder={language.zendesk_support_link}
                      disabled={routeType === "edit"}
                    />
                  )}
                />

                <FormFullScreenLayout
                  label={language.organisation_key}
                  rightRender={() => (
                    <SntOrganizationSelector
                      isDefaultValue={true}
                      value={ownedByOrg.value}
                      onChange={(e) => {
                        setOwnedByOrg(e);
                        setFieldValue("ownedByOrg", { id: e.value });
                      }}
                      disabled={routeType === "edit"}
                    />
                  )}
                />

                {ownedByOrg.type === "NORMAL" ? (
                  <FormFullScreenLayout
                    rightRender={() => (
                      <InputCheck
                        name="handledByPartner"
                        label={language.handled_by_partner}
                        disabled={routeType === "edit"}
                      />
                    )}
                  />
                ) : (
                  ""
                )}

                <FormFullScreenLayout
                  label={language.status_key}
                  isRequired={true}
                  rightRender={() => (
                    <ItemSelector
                      name="status"
                      options={statusOptions}
                      handleChange={(value) => setFieldValue("status", value)}
                    />
                  )}
                />

                <FormFullScreenLayout
                  label={language.description_key}
                  rightRender={() => (
                    <InputTextArea
                      name="description"
                      placeholder={language.description_key}
                      disabled={routeType === "edit"}
                    />
                  )}
                />

                <FormFullScreenLayout
                  label={language.internal_description}
                  rightRender={() => (
                    <InputTextArea
                      name="internalDescription"
                      placeholder={language.internal_description}
                    />
                  )}
                />

                {routeType === "edit" && (
                  <FormFullScreenLayout
                    label={language.device_return}
                    rightRender={() => rmaDeclaration?.deviceSerials.join()}
                  />
                )}
              </SntPanel>

              <SntPanel title={language.trackers_to_return}>
                <FormFullScreenLayout
                  label={
                    language.alert_generic_config_monitoredassets_field_label
                  }
                  rightRender={() => (
                    <MonitoredAssets
                      completedLoadingData={completedLoadingData}
                      name="monitoredDevices"
                      onChange={(value) => {
                        setFieldValue("monitoredDevices", value);
                      }}
                      orgId={ownedByOrg.value}
                      disabled={routeType === "edit" || !ownedByOrg.value}
                    />
                  )}
                />
              </SntPanel>

              <Col xs="12" className="mb-3">
                <Button
                  variant="sensolus"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {language.save_key}
                </Button>
                <Button
                  variant="outline-sensolus"
                  className="ms-2"
                  onClick={() => history.push(`/rma_declaration`)}
                >
                  {language.cancel_key}
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RMADeclarationManage;
