const _activeAlertOptions = (loginInfo, orgId, isPartner) => {
  let { features } = loginInfo;
  let queryObjectType = "ACTIVE_ALERT";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["filter_severity", "filter_rule"],
      restrictedFilters: [
        "tabcol_aggregated_data",
        "alert_monitoredEntity_device_ownedBy_orgId",
        "bound",
        "additional_info",
        "filter_organisation",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: [
        "tabcol_aggregated_data",
        "alert_monitoredEntity_device_ownedBy_orgId",
        "bound",
      ],
      columnVisibleInOrder: [],
      visibleCols: [
        "tabcol_monitored_item",
        "tabcol_severity",
        "tabcol_rule",
        "tabcol_raised",
        "tabcol_time_open",
        "tabcol_comment",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };

  let defaultColumnInit = pageOptions.tableOptions.visibleCols;
  if (features.alert_ack || features.alert_assign || features.alert_comment) {
    defaultColumnInit.push("tabcol_status");
  }
  if (features.alert_assign) {
    defaultColumnInit.push("tabcol_assigned_to");
  }
  if (
    features.alert_ack ||
    features.alert_assign ||
    features.alert_comment ||
    features.alert_suppress
  ) {
    defaultColumnInit.push("actions");
  }

  return pageOptions;
};

export default _activeAlertOptions;
