const _eolDeviceOptions = (loginInfo, orgId, isPartner) => {
  const { features } = loginInfo;

  let queryObjectType = "SIGFOX_DEVICE";
  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: ["deviceIdentifier", "assetTags"],
      restrictedFilters: [
        "assetName",
        "assetTagsName",
        "locationByBound",
        "deviceCategory",
        "lastNetworkOverridePrecise",
        "alertRuleName",
        "decoratorColor",
        "organizations",
      ],
      categories: null,
      deviceCategorySuggestion: null,
      includeDynamicFilter: false,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: null,
      preventCols: ["organizations"],
      columnVisibleInOrder: [],
      visibleCols: [
        "image",
        "name",
        "serial",
        "deviceTag",
        "techProductDescriptorKey",
        "_ownedName",
        "status",
        "remainingBattery",
        "actions",
      ],
      includeDynamicColumn: false,
      pageSize: 25,
      pageIndex: 0,
      orderData: null,
      groups: null,
    },
  };
  let visibleCols = pageOptions.tableOptions.visibleCols;
  pageOptions.tableOptions.visibleCols = features.whitelabel_identifiers
    ? visibleCols.map((key) => (key === "serial" ? "secondarySerial" : key))
    : visibleCols;
  return pageOptions;
};

export default _eolDeviceOptions;
