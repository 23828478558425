import { DEVICE_SUMMARY_KEY } from "@containers/SaveViewContainer/pageUtils/PageUtils.js";

const _trackerAdminOptions = (loginInfo, orgId, isPartner) => {
  const { features, organisation } = loginInfo;
  let queryObjectType = "SIGFOX_DEVICE";

  let pageOptions = {
    filterOptions: {
      searchQueryType: "BASIC",
      queryObjectType: queryObjectType,
      filtersVisibleAtInit: [
        "deviceIdentifier",
        "trackerStatus",
        "sigfoxContractEnd",
        "remainingBattery",
      ],
      restrictedFilters: [
        "assetName",
        "assetTagsName",
        // "locationByBound",
        "deviceCategory",
        "lastNetworkOverridePrecise",
        "alertRuleName",
        "decoratorColor",
        "lastGeozoneTags",
        "lastGeozone",
        "assetTags",
      ],
      categories: ["DEVICE"],
      deviceCategorySuggestion: "TRACKER",
      includeDynamicFilter: true,
    },
    tableOptions: {
      queryObjectType: queryObjectType,
      categories: ["DEVICE"],
      preventCols: ["location"],
      visibleCols: [
        "image",
        "name",
        "serial",
        "deviceTag",
        "techProductDescriptorKey",
        "_ownedName",
        "status",
        "remainingBattery",
        "actions",
      ],
      includeDynamicColumn: true,
      pageSize: 25,
      pageIndex: 0,
      columnVisibleInOrder: [],
      orderData: null,
      groups: null,
    },
    mapOptions: {
      queryObjectType: queryObjectType,
      categories: ["DEVICE"],
      preventCols: ["name", "location"],
      visibleCols: [DEVICE_SUMMARY_KEY],
      includeDynamicColumn: true,
      showCellLabels: false,
    },
  };

  if (orgId) {
    pageOptions.filterOptions.restrictedFilters = [
      "assetName",
      "assetTagsName",
      // "locationByBound",
      "deviceCategory",
      "lastNetworkOverridePrecise",
      "alertRuleName",
      "decoratorColor",
      "organizations",
    ];
  }
  let visibleCols = pageOptions.tableOptions.visibleCols;
  let preventCols = pageOptions.tableOptions.preventCols;
  pageOptions.tableOptions.visibleCols = features.whitelabel_identifiers
    ? visibleCols.map((key) => (key === "serial" ? "secondarySerial" : key))
    : visibleCols;

  pageOptions.tableOptions.preventCols =
    features.whitelabel_identifiers &&
    organisation.organisationType === "NORMAL"
      ? [...preventCols, "serial"]
      : preventCols;

  return pageOptions;
};

export default _trackerAdminOptions;
